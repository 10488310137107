export const FileUtility = {
    fileToBase64: function (file, callBack) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const fileContents = {
                base64: reader.result,
                name: file.name,
                size: file.size,
                mime_type: file.type
            }
            callBack(fileContents)
        };
        reader.onerror = (error) => {
            console.log('Error: ', error);
        };
    }
};
