import React from "react";
import {displayForPermissions} from "../../common/AuthorizedComponent";
import {Constants} from "../../common/Constants";
import {UrlUtility} from "../../common/UrlUtility";
import {ErrorUtility} from "../../common/ErrorUtility";
import {ReactTable} from "../../common/ReactTable";
import {PortalApi} from "../../common/PortalApi";
import {EditQuizQuestionModal} from "./EditQuizQuestionModal";
import Switch from "rc-switch";
import {Header} from "../../common/Header";
import {FooterMenu} from "../../common/FooterMenu";

export const QuizQuestionsPage = displayForPermissions(
    [Constants.permissions.settingsManage],
    class extends React.Component {
        constructor(props) {
            super(props);

            const urlParams = UrlUtility.getParamsFromUrl(props.location.search);
            const filter = UrlUtility.getParamValue(urlParams, "filter", []);
            const sort = UrlUtility.getParamValue(urlParams, "sort", []);
            const pageSize = UrlUtility.getParamValue(urlParams, "pageSize", Constants.defaultSettingsUsersPageSize);

            this.state = {
                loading: false,
                sort: sort,
                page: 0,
                filter: filter,
                pageSize: pageSize,
                quizQuestionsList: [],
                showQuizQuestionAddPopup: false
            };
        }

        componentDidMount = () => {
            this.navigateToFilteredPage();
        }

        refreshPageData = (state = null) => {
            this.setState(oldState => {
                return {
                    loading: true,
                    filter: state ? state.filter : oldState.filter,
                    sort: state ? state.sort : oldState.sort,
                    page: state ? state.page : oldState.page,
                    pageSize: state ? state.pageSize : oldState.pageSize
                }
            }, async () => {
                const params = {
                    filter: this.state.filter,
                    sort: this.state.sort,
                    page: this.state.page,
                    pageSize: this.state.pageSize,
                    showAll: true,
                };
                try {
                    const quizQuestionsResponse = await PortalApi.fetchQuizQuestions(params);

                    this.setState({
                        loading: false,
                        quizQuestionsList: quizQuestionsResponse.quiz_questions,
                        filter: this.state.filter,
                        sort: this.state.sort,
                        page: this.state.page,
                        pageSize: this.state.pageSize,
                        pages: Math.ceil(parseInt(quizQuestionsResponse.count) / this.state.pageSize)
                    }, () => {
                        this.navigateToFilteredPage();
                    });

                } catch (err) {
                    ErrorUtility.handleError(err, 'Internal server error: Can&#039;t fetch quiz questions list');
                    this.setState({loading: false});
                };
            });
        }

        navigateToFilteredPage = () => {
            const {filter, sort, pageSize} = this.state;

            let query = {};
            if (filter != null) {
                query['filter'] = filter;
            }
            if (sort != null) {
                query['sort'] = sort;
            }
            if (pageSize != null) {
                query['pageSize'] = pageSize;
            }

            this.props.history.push('/settings/quiz-questions?' + $.param(query));
        }

        toggleQuizQuestion = (quizQuestionId, isActive = true) => {
            this.setState({
                loading: true
            }, async () => {
                try {
                    const response = await PortalApi.updateQuizQuestion({
                        quiz_question_id: quizQuestionId,
                        active: isActive
                    });

                    if (typeof response.message !== "undefined" && response.message) {
                        toastr.success(response.message);
                    } else {
                        toastr.success("Quiz question updated");
                    }

                    this.setState({
                        loading: false
                    }, () => {this.refreshPageData();});
                } catch (err) {
                    ErrorUtility.handleError(err, 'An error occurred while update quiz question');
                    this.setState({
                        loading: false
                    });
                }
            });
        }

        getQuizQuestionByIdFromList = (id) => {
            if (!this.state.quizQuestionsList || !id) {
                return null;
            }
            return this.state.quizQuestionsList.find((quizQuestion) => {
                if (quizQuestion.id == id) {
                    return quizQuestion;
                }
            });
        }

        toggleQuizQuestionAddPopup = (quizQuestionId = null) => {
            this.setState ({
                quizQuestionForEditId: quizQuestionId,
                showQuizQuestionAddPopup: !this.state.showQuizQuestionAddPopup
            });
        }

        afterQuizQuestionSave = () => {
            this.setState({
                showQuizQuestionAddPopup: false,
                quizQuestionForEditId: null
            }, () => {this.refreshPageData();});
        }

        getTableColumns = () => {
            const columns = [{
                Header: () => (
                    <span>
                        Question
                        <img
                            src="/static/image/icon-filter-down.svg"
                        />
                    </span>
                ),
                accessor: 'title'
            }, {
                Header: () => (
                    <span>
                        Correct Answer
                        <img
                            src="/static/image/icon-filter-down.svg"
                        />
                    </span>
                ),
                Cell: ({row}) => (
                    <span>{row.original.correct_answer_yes ? "YES" : "NO"}</span>
                ),
                accessor: 'correct_answer_yes',
                disableFilters: true,
                resizable: false,
            }, {
                Header: () => (
                    <span>
                        Is Bonus Question
                        <img
                            src="/static/image/icon-filter-down.svg"
                        />
                    </span>
                ),
                Cell: ({row}) => (
                    <span>{row.original.is_bonus ? "YES" : ""}</span>
                ),
                accessor: 'is_bonus',
                disableFilters: true,
                resizable: false,
            }, {
                Header: () => (
                    <span>
                        Order
                        <img
                            src="/static/image/icon-filter-down.svg"
                        />
                    </span>
                ),
                accessor: 'order',
                disableFilters: true,
                resizable: false,
            }, {
                accessor: 'id',
                Cell: ({row}) => (
                    <Switch
                        height={20}
                        width={40}
                        checked={!!row.original.active}
                        onChange={() => {
                            this.toggleQuizQuestion(row.original.id, !row.original.active);
                        }}
                    />
                ),
                resizable: false,
                disableFilters: true,
            }];

            return columns;
        }

        render = () => {
            return (
                <div className="page-wrapper quiz-questions-page">
                    <Header
                        history={this.props.history}
                    />
                    <div className="page-heading">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="page-title">Quiz Questions</h1>
                                    <p>Questions display in ascending order in the app.</p>
                                    <div className="page-heading-controls">
                                        <button className="compact" onClick={() => {this.toggleQuizQuestionAddPopup();}}>Add New Quiz Question</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ReactTable
                                        data={this.state.quizQuestionsList}
                                        columns={this.getTableColumns()}
                                        className={'quiz-questions-list-content'}
                                        pages={this.state.pages}
                                        loading={this.state.loading}
                                        defaultSorted={this.state.sort}
                                        defaultFiltered={this.state.filter}
                                        defaultPageSize={this.state.pageSize}
                                        onFetchData={(state) => {this.refreshPageData(state)}}
                                        getTdProps={({row, column}) => {
                                            return {
                                                onClick: () => {
                                                    if(column.id !== "id") {
                                                        this.toggleQuizQuestionAddPopup(row.original.id);
                                                    }
                                                }
                                            };
                                        }}
                                    />
                                    {this.state.showQuizQuestionAddPopup &&
                                        <EditQuizQuestionModal
                                            toggleModal={this.toggleQuizQuestionAddPopup}
                                            afterSubmitListener={this.afterQuizQuestionSave}
                                            quizQuestionForEdit={this.getQuizQuestionByIdFromList(this.state.quizQuestionForEditId)}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMenu />
                </div>
            )
        }
    }
);