import {Constants} from "./Constants";
import {TextUtility} from "./TextUtility";
import {ErrorUtility} from "./ErrorUtility";
import moment from "moment";

export const ValidationUtility = {
	validateEmail: function (email = null) {
		const emailRegex = Constants.regexes.email;
		return email && emailRegex.test(email);
   	},

    validatePhone: function (phone = null) {
		const phoneRegexp = Constants.regexes.phone;
		return phone && phoneRegexp.test(phone);
	},

	validateText: function (text = null) {
		return TextUtility.notEmpty(text);
	},

	validateDate: function (date = null) {
		return date && moment(date).isValid();
	},

	validatePassword: function (password) {
		const passwordRegexp = Constants.regexes.password;
		return password && passwordRegexp.test(password);
	},

	validateUrl: function (url) {
		const urlRegexp = Constants.regexes.url;
		return urlRegexp.test(url);
	},

	showErrorMessages: function (errorMessages = []) {
		if (errorMessages && errorMessages.length) {
			errorMessages.map( (message) => {
				ErrorUtility.handleError(false, message);
			});
		}
	},
};
