let queryString = require('qs');

export const UrlUtility = {
    filterQueryReplace: function (filterQuery, pattern = /#/g) {
        return filterQuery.replace(pattern, '');
    },

    getParamsFromUrl: function (url = null) {
        return queryString.parse(url, {ignoreQueryPrefix: true});
    },

    getParamValue: function (urlParams, paramName, defaultValue = null) {
        if (typeof urlParams == "string") {
            urlParams = this.getParamsFromUrl(urlParams);
        }
        const value = typeof urlParams[paramName] !== "undefined"
            ? urlParams[paramName]
            : defaultValue;
        return value;
    }
};