import React from "react";
import {CommonModal} from "../../common/CommonModal";
import {Constants} from "../../common/Constants";
import {LoadingIndicator} from "../../common/LoadingIndicator";

export const DeletePodcastModal = class extends React.Component {
    constructor (props) {
        super(props);

        this.state = {};
    }

    handleDelete = () => {
        this.props.afterSubmitListener(this.props.podcastForEdit);
    }

    render = () => {
        return (
            <CommonModal
                modalTitle={"Are you sure?"}
                onClose={this.props.toggleModal}
                modalType={Constants.modal.type.delete}
            >
                {this.props.loading &&
                    <LoadingIndicator />
                }
                <div className="modal-row">
                    <div>
                        <p>You are about to delete this Podcast?</p>
                    </div>
                </div>
                <div className="controls">
                    <button className="cancel cancel-delete" onClick={this.props.toggleModal}>Cancel</button>
                    <button className="delete" onClick={this.handleDelete}>Delete</button>
                </div>
            </CommonModal>
        );
    }
};
