import {AuthUtility} from "./AuthUtility";

export const UserUtility = {
    getUsernameFromCookies: function () {
        const userData = AuthUtility.getUserDataFromCookie();
		let usernameParts = [];
		if (userData && userData.first_name && userData.first_name.length) {
			usernameParts.push(userData.first_name);
		}``
		if (userData && userData.last_name && userData.last_name.length) {
			usernameParts.push(userData.last_name);
		}
		return usernameParts.join(" ");
    },
};
