import React from "react";
import Routes from "../routes/Index";
import {withRouter} from "react-router-dom";

class App extends React.Component {
    constructor(props) {
        super(props);

        // set toastr options for app
        toastr.options.closeButton = true;
        toastr.options.closeMethod = 'fadeOut';
        toastr.options.closeDuration = 300;
        toastr.options.closeEasing = 'swing';
        toastr.options.positionClass = "toast-top-right";

        // Set State
        this.state = {};
    }

    render = () => {
        return (
            <div>
                {Routes}
            </div>
        );
    }
};

export default App;
