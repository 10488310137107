import React from "react";
import {
    useTable,
    useResizeColumns,
    useFlexLayout,
    useExpanded,
    usePagination,
    useFilters,
    useSortBy
} from "react-table";

import {default as DefaultPaginationComponent} from "../common/PaginationComponent";

export const ReactTable = (props) => {
    const {
        className = "",
        data = [],
        columns = {},
        loading = false,
        getTdProps = () => {},
        getTrProps = () => {},
        trClassName = "",
        SubComponent = null,
        PaginationComponent = DefaultPaginationComponent,
        pages,
        defaultPageSize,
        previousText,
        nextText,
        onFetchData = () => {},
        defaultExpanded = {},
        onExpandedChange = () => {},
        defaultFiltered = [],
        defaultSorted = [],
        manualFilters = true,
        defaultCanFilter = true,
        manualSortBy = true,
        disableSortRemove = true,
        disableMultiRemove = true,
        manualPagination = true,
        showPagination = true,
        disableFilters = false,
        disableSortBy = false,
    } = props;

    const defaultColumn = {
        minWidth: 30,
        width: 50,
        Header: '',
        Filter: DefaultColumnFilter,
    };

    if (SubComponent) {
        columns.unshift({
            Header: "",
            id: "expander",
            resizable: false,
            className: "rt-expandable",
            width: 35,
            disableFilters: true,
            maxWidth: 35,
            Cell: ({ row }) => {
                return (
                    <div
                        title="Click here to see more information"
                        className="p-0"
                    >
                        <div
                            className={
                                ["rt-expander",
                                    row.isExpanded ? "-open" : ""].join(' ')
                            }
                        >
                            •
                        </div>
                    </div>
                );
            }
        });
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {
            pageIndex,
            pageSize,
            expanded,
            filters,
            sortBy
        },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            SubComponent,
            getTrProps,
            getTdProps,
            initialState: {
                pageIndex: 0,
                pageSize: defaultPageSize,
                expanded: defaultExpanded,
                filters: defaultFiltered,
                sortBy: defaultSorted
            },
            manualPagination: manualPagination,
            pageCount: pages,
            manualFilters: manualFilters,
            defaultCanFilter: defaultCanFilter,
            manualSortBy: manualSortBy,
            disableSortRemove: disableSortRemove,
            disableMultiRemove: disableMultiRemove,
            disableSortBy: disableSortBy,
        },
        useFilters,
        useSortBy,
        useFlexLayout,
        useResizeColumns,
        useExpanded,
        usePagination,
    );

    React.useEffect(() => {
        onFetchData({
            page: pageIndex,
            pageSize: pageSize,
            filter: filters,
            sort: sortBy
        })
    }, [pageIndex, pageSize, filters, sortBy]);

    React.useEffect(() => {
        onExpandedChange(expanded)
    }, [expanded]);

    const getExpanderColumnStyle = (column) => {
        return column.id === "expander" && column.maxWidth ? {style: {maxWidth: column.maxWidth + "px"}} : {};
    };

    const addExpanderProps = (cell) => {
        return cell.column
        && cell.column.id
        && cell.column.id === "expander"
        && cell.row
        && cell.row.getToggleRowExpandedProps ?
            cell.row.getToggleRowExpandedProps()
            : {};
    };

    return (
        <div className={[className, "ReactTable"].join(' ')}>
            <div {...getTableProps} className="rt-table">
                <div className="rt-thead -header">
                    {headerGroups.map((headerGroup) => (
                        <div {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                            {headerGroup.headers.map((column) => {
                                const sortingClass = !column.isSorted || column.isSortedDesc ? "-sort-desc" : "-sort-asc";
                                return column.resizable === false ? (
                                    <div
                                        {...column.getHeaderProps(getExpanderColumnStyle(column))}
                                        className={"rt-th " + sortingClass}
                                    >
                                        <div
                                            {...column.getSortByToggleProps()}
                                            className="rt-header-content"
                                        >
                                            {column.render("Header")}
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        {...column.getHeaderProps(getExpanderColumnStyle(column))}
                                        className={"rt-th rt-resizable-header " + sortingClass}
                                    >
                                        <div
                                            {...column.getSortByToggleProps()}
                                            className="rt-resizable-header-content"
                                        >
                                            {column.render("Header")}
                                        </div>
                                        <div {...column.getResizerProps()} className="rt-resizer" />
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>
                {!disableFilters &&
                <div className="rt-thead -filters">
                    {headerGroups.map((headerGroup, key) => (
                        <div
                            {...headerGroup.getHeaderGroupProps()}
                            className="rt-tr"
                            key={"filter-group-" + key}
                        >
                            {headerGroup.headers.map((column, key) => {
                                return (
                                    <div
                                        {...column.getHeaderProps(getExpanderColumnStyle(column))}
                                        className="rt-th"
                                        key={"filter-" + key}
                                    >
                                        {column.canFilter && !column.disableFilters ? column.render('Filter') : null}
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>
                }
                <div {...getTableBodyProps()} className="rt-tbody">
                    {rows.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                            <div
                                className="rt-tr-group"
                                key={"group-" + rowIndex}
                            >
                                <React.Fragment key={row.id}>
                                    <div
                                        {...getTrProps(row)}
                                        {...row.getRowProps()}
                                        className={[
                                            "rt-tr",
                                            rowIndex % 2 === 0 ? "-odd" : "-even",
                                            trClassName].join(' ')
                                        }
                                    >
                                        {row.cells.map((cell, index) => {
                                            return (
                                                <div
                                                    key={"cell-" + index}
                                                    {...getTdProps(cell)}
                                                    {...cell.getCellProps()}
                                                    {...addExpanderProps(cell)}
                                                    {...getExpanderColumnStyle(cell.column)}
                                                    className={["rt-td", cell.column.className].join(' ')}
                                                >
                                                    {cell.render("Cell")}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {row.isExpanded ? SubComponent({ row }) : null}
                                </React.Fragment>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={["-loading", loading ? "-active" : ""].join(' ')}>
                <div className="-loading-inner">Loading...</div>
            </div>
            {!rows || !rows.length &&
            <div className="rt-noData">No rows found</div>
            }
            <div className={'pagination-bottom'}>
                {showPagination && pages > 1 &&
                <PaginationComponent
                    pages={pages}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageOptions={pageOptions}
                    pageCount={pageCount}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    setPageSize={setPageSize}
                    NextTextComponent={nextText}
                    PreviousTextComponent={previousText}
                />
                }
            </div>
        </div>
    );
};

const DefaultColumnFilter = ({column: {id, filterValue, setFilter}}) => {
    return (
        <input
            key={"filter-for-" + id}
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        />
    )
};