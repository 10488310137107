import React from "react";
import {BrowserRouter as Router, Route, Switch, Redirect, HashRouter} from "react-router-dom";
import Login from "../components/Login/Login";
import Register from "../components/Register/Register";
import SendRestorePasswordCode from "../components/RestorePassword/SendRestorePasswordCode";
import ResetPassword from "../components/RestorePassword/ResetPassword";
import {ArticlesPage} from "../components/Settings/Articles/ArticlesPage";
import {PodcastsPage} from "../components/Settings/Podcasts/PodcastsPage";
import {UsersPage} from "../components/Settings/Users/UsersPage";
import {QuizQuestionsPage} from "../components/Settings/QuizQuestions/QuizQuestionsPage";
import {NotFoundPage} from "../components/NotFound/NotFoundPage";
import PrivateRoute from "../components/common/PrivateRoute";

export default (
    <Router>
        <Switch>PodcastsPage
            <Route path="/login" exact component={Login} />
            <Route path="/register" exact component={Register} />
            <Route path="/send-restore-password-code" exact component={SendRestorePasswordCode} />
            <Route path="/reset-password" exact component={ResetPassword} />
            <Redirect from="/" to="/settings/users" exact/>
            <PrivateRoute path="/settings/users" component={UsersPage} />
            <PrivateRoute path="/settings/articles" component={ArticlesPage} />
            <PrivateRoute path="/settings/quiz-questions" component={QuizQuestionsPage} />
            <PrivateRoute path="/settings/podcasts" component={PodcastsPage} />

            <Route path="/404" exact component={NotFoundPage} />
            <Redirect from='*' to='/404' />
        </Switch>
    </Router>
);
