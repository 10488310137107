import React from "react";

export const LoadingIndicator = class extends React.Component {
    render = () => {
        return (
            <div className={"loading-indicator-overlay overlay-style-loader"}>
                <div className="loading-indicator">
                    <div className="spinner">
                        <div className="bounce"></div>
                        <div className="bounce"></div>
                        <div className="bounce"></div>
                    </div>
                </div>
            </div>
        );
    }
};