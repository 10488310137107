import React from "react";
import {Constants} from "../../common/Constants";
import {ErrorUtility} from "../../common/ErrorUtility";
import {CommonModal} from "../../common/CommonModal";
import {PortalApi} from "../../common/PortalApi";
import {ValidationUtility} from "../../common/ValidationUtility";
import {FileUtility} from "../../common/FileUtility";
import {LoadingIndicator} from "../../common/LoadingIndicator";

export const EditArticleModal = class extends React.Component {
    constructor (props) {
        super(props);
        const article = props.articleForEdit;

        this.state = {
            article: {
                article_id: article ? article.id : '',
                title: article ? article.title : '',
                description: article ? article.description : '',
                order: article ? article.order : Constants.defaultArticleOrder,
                source: article ? article.source : '',
                url: article ? article.url : '',
                file_item_id: article ? article.file_item_id : null,
                file_item: article ? article.file_item : null
            },
            loading: false
        };
    }

    loadingSetter = (value = false, callback = () => {}) => {
        this.setState({
            loading: value
        }, callback);
    }

    handleChange = (event) => {
        let state = this.state;
        const hashFields = event.target.name.split(".");
        state.article = this.updateHashItem(state.article, hashFields, 0, event.target.value);
        this.setState(state);
    }

    updateHashItem = (result, hash, index, newVal) => {
        const key = hash[index];

        result[key] = (hash.length === index + 1)
            ? newVal
            : this.updateHashItem(result[key], hash, index + 1, newVal);

        return result;
    }

    validate = function () {
        let errorMessages = [];
        const {
            title,
            description,
            source,
            url
        } = this.state.article;

        if (!title || !title.length) {
            errorMessages.push('Set Title');
        }

        if (!description || !description.length) {
            errorMessages.push('Set Description');
        }

        if (!source || !source.length) {
            errorMessages.push('Set Source');
        }

        if (!url || !ValidationUtility.validateUrl(url)) {
            errorMessages.push('Invalid Url');
        }

        if (errorMessages.length) {
            errorMessages.map( (message) => {
                ErrorUtility.handleError(false, message);
            });
        }

        return !errorMessages.length;
    }

    getArticleDataForSubmission = () => {
        const article = this.state.article;
        return {
            article_id: article ? article.article_id : null,
            title: article ? article.title : null,
            description: article ? article.description : null,
            order: article ? article.order : Constants.defaultArticleOrder,
            source: article ? article.source : null,
            file_item_id: article ? article.file_item_id : null,
            url: article ? article.url : null
        }
    }

    handleSave = () => {
        const isValid = this.validate();
        if (!isValid) {
            return;
        }

        this.loadingSetter(true, async () => {
            try {
                const articleData = this.getArticleDataForSubmission();
                const response = await PortalApi.updateArticle(articleData);

                toastr.success(response.message);
                this.loadingSetter(false);
                this.props.afterSubmitListener();
            } catch (err) {
                this.loadingSetter(false);
                ErrorUtility.handleError(err, 'An error occurred while save an article.');
            }
        });
    }

    onImageUploadSuccess = (fileObject) => {
        // Upload file on server
        PortalApi.uploadFile(fileObject)
            .then((response) => {
                // response.file_item - newly uploaded file
                let state = this.state;
                state.loading = false;
                state.article.file_item = response.file_item;
                state.article.file_item_id = response.file_item.id;
                this.setState(state);
            })
            .catch((error) => {
                console.error(">> ERROR during file upload: ", error);
                this.setState({loading: false});
            });
    }

    onImageChange = (event) => {
        this.setState({loading: true}, () => {
            FileUtility.fileToBase64(event.target.files[0], this.onImageUploadSuccess);
        });
    }

    onRemoveImage = () => {
        let state = this.state;
        state.article.file_item = null;
        state.article.file_item_id = null;
        this.setState(state);
    }

    onIncreaseOrder = () => {
        let state = this.state;
        state.article.order = this.state.article.order + 1;

        this.setState({state});
    }

    onDecreaseOrder = () => {
        let state = this.state;
        state.article.order = this.state.article.order - 1;

        this.setState({state});
    }

    render = () => {
        const isArticleEdit = !!this.props.articleForEdit;

        const {
            title,
            description,
            source,
            order,
            url,
            file_item
        } = this.state.article;

        return (
            <CommonModal
                modalTitle={(isArticleEdit ? 'Edit' : 'Add New') + ' Article'}
                onClose={this.props.toggleModal}
            >
                {this.state.loading &&
                    <LoadingIndicator />
                }
                <div className="modal-row">
                    <div>
                        <label>Title</label>
                        <input
                            type="text"
                            name="title"
                            placeholder="Title"
                            value={title}
                            onChange={this.handleChange}
                            onBlur={(e) => {
                                this.handleChange({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.value ? e.target.value.trim() : null
                                    }
                                });
                            }}
                            maxLength={128}
                        />
                    </div>
                </div>
                <div className="modal-row">
                    <div>
                        <label>Description (200 character limit)</label>
                        <textarea
                            name="description"
                            maxLength={200}
                            className="mh-100"
                            value={description}
                            onChange={this.handleChange}
                            onBlur={(e) => {
                                this.handleChange({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.value ? e.target.value.trim() : null
                                    }
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="modal-row">
                    <div>
                        <label>Source</label>
                        <input
                            type="text"
                            name="source"
                            placeholder="Source"
                            value={source}
                            onChange={this.handleChange}
                            onBlur={(e) => {
                                this.handleChange({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.value ? e.target.value.trim() : null
                                    }
                                });
                            }}
                            maxLength={64}
                        />
                    </div>
                    <div>
                        <label>Url</label>
                        <input
                            type="text"
                            name="url"
                            placeholder="URL"
                            value={url}
                            onChange={this.handleChange}
                            onBlur={(e) => {
                                this.handleChange({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.value ? e.target.value.trim() : null
                                    }
                                });
                            }}
                            maxLength={1024}
                        />
                    </div>
                    <div className="w-150">
                        <label>Order</label>
                        <div className="number">
                            <button className="number-minus" type="button"
                                    onClick={() => {this.onDecreaseOrder()}}
                            >
                                -
                            </button>
                            <input
                                type="number"
                                name="order"
                                placeholder="Order"
                                value={order}
                                onChange={this.handleChange}
                                onBlur={(e) => {
                                    this.handleChange({
                                        target: {
                                            name: e.target.name,
                                            value: e.target.value ? e.target.value.trim() : null
                                        }
                                    });
                                }}
                                maxLength={4}
                            />
                            <button className="number-plus" type="button"
                                    onClick={() => {this.onIncreaseOrder()}}
                            >
                                +
                            </button>

                        </div>
                    </div>
                </div>
                <div className="modal-row">
                    <div>
                        <label>Featured Image</label>
                        <div className="modal-row mb-0">
                            <div>
                                <div className="attachment-wrapper">
                                    {file_item && file_item.path && file_item.path.hasOwnProperty("url")
                                        ?   <img className="img-responsive" src={file_item.path.url} />
                                        :   <img className="img-responsive" src='/static/image/icon-attachment.svg' alt='attachment-icon' />
                                    }
                                </div>
                            </div>
                            <div className="w-auto">
                                <label className="file-input-wrapper">
                                    Add New Image
                                    <input
                                        type="file"
                                        accept="image/*"
                                        multiple={false}
                                        onChange={this.onImageChange}
                                    />
                                </label>
                                {this.state.article.file_item_id &&
                                    <button onClick={this.onRemoveImage}>Remove Image</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="controls">
                    <button className="cancel" onClick={this.props.toggleModal}>Cancel</button>
                    <button onClick={this.handleSave}>Save and Close</button>
                </div>
            </CommonModal>
        );
    }
};
