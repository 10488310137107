import React from "react";
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import {withRouter} from "react-router";
import {Constants} from "./Constants";
import {AuthUtility} from "./AuthUtility";
import {UserUtility} from "./UserUtility";
import {ErrorUtility} from "./ErrorUtility";
import {PortalApi} from "./PortalApi";
import {NavLink} from "react-router-dom";

export const Header = withRouter(class extends React.Component {
    static contextTypes = {
        navigator: PropTypes.object
    }

    constructor (props) {
        super(props);
        this.state = {};
    }

    componentDidMount = () => {
        if (AuthUtility.isLoggedIn() && AuthUtility.isTokenExpired()) {
            this.refreshToken();
        }
    }

    refreshToken = () => {
        try {
            const token = Cookies.get("token");
            PortalApi.refreshToken(token).then((response) => {
                if (
                    !response.hasOwnProperty("user_data") ||
                    !response.user_data.hasOwnProperty("authentication_token")
                    || !response.user_data.authentication_token
                ) {
                    ErrorUtility.handleError(null, 'An error occurred while token refresh. Logout');
                    this.handleLogOut();
                } else {
                    AuthUtility.setUserDataToCookie(response.user_data);
                }
            })
            .catch( error => {
                ErrorUtility.handleError(error, 'An error occurred while token refresh. Logout');
                this.handleLogOut();
            })
        } catch (error) {
            ErrorUtility.handleError(error, 'An error occurred while token refresh. Logout');
            this.handleLogOut();
        }
    }

    handleLogOut = () => {
        try {
            AuthUtility.resetUserDataInCookies();
            toastr.success("Log out successful");
            this.props.history.push(Constants.routes.login);
        } catch (err) {
            ErrorUtility.handleError(err, 'An error occurred while logging out.');
        }
    }

    goHome = () => {
        const isLoggedIn = AuthUtility.isLoggedIn();
        if (!isLoggedIn) {
            this.props.history.push(Constants.routes.login);
        }
        this.props.history.push('/');
    }

    render = () => {
        const isLoggedIn = AuthUtility.isLoggedIn();
        if (!isLoggedIn) {
            return (
                <div className='header'>
                    <div className="header-main center-aligned">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="header-main-inner">
                                        <div className="logo" onClick={this.goHome}>
                                            <img
                                                src='/static/image/icon-logo.svg'
                                                alt='Logotype'
                                            />
                                            <h3 className="logo-text">Nutrition GPA</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        const userData = AuthUtility.getUserDataFromCookie();
        const username = UserUtility.getUsernameFromCookies();
        return (
            <div className='header'>
                <div className="header-main">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="header-main-inner">
                                    <div className="logo" onClick={this.goHome}>
                                        <img
                                            src='/static/image/logo.svg'
                                            alt='Logotype'
                                        />
                                    </div>
                                    <div className="header-column-last">
                                        {username &&
                                        <div>Welcome back <span className="accent">{username}</span></div>
                                        }
                                        <span className="link" onClick={() => {this.handleLogOut();}}>Logout</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-menu">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <NavLink to={Constants.routes.settingsUsers} activeClassName={'active'}>Users</NavLink>
                                <NavLink to={Constants.routes.settingsArticles} activeClassName={'active'}>Curated Articles</NavLink>
                                <NavLink to={Constants.routes.settingsPodcasts} activeClassName={'active'}>Curated Podcasts</NavLink>
                                <NavLink to={Constants.routes.settingsQuizQuestions} activeClassName={'active'}>Quiz Questions</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});
