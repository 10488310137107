import React from "react";

const routes = {
    login: '/login',
    register: '/register',
    sendRestorePasswordCode: '/send-restore-password-code',
    resetPassword: '/reset-password',
    settingsUsers: '/settings/users',
    settingsArticles: '/settings/articles',
    settingsPodcasts: '/settings/podcasts',
    settingsQuizQuestions: '/settings/quiz-questions'
}

let permissions = {
    settingsManage: "ManageSettings",
    quizRead: 'QuizRead',
    quizSubmit: 'QuizSubmit'
}

const regexes = {
    float: /^[0-9]*\.?[0-9]{0,2}$/,
    int: /^[0-9]*$/,
    email: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    phone: /^(\+?[0-9]\s?)?((\([0-9]{3}\))|[0-9]{3}|(\-[0-9]{3}))[\s\-]?[a-zA-Z0-9]{3}[\s\-]?[a-zA-Z0-9]{4}$/,
    password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d~@$!%*#?&]{8,}$/,
    url: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
}

export let Constants = {
    routes: routes,
    regexes: regexes,
    permissions: permissions,

    tokenExpireTime: 0.01, // Roughly 15 minutes

    toggleOnColor: '#005985',

    popupState: {
        open: 'modal-is-open',
    },

    modal: {
        type: {
            regular: 'regular',
            primary: 'primary',
            delete: 'delete',
            reactivate: 'reactivate',
        },
        style: {
            regular: 'regular',
            light: 'light',
        },
    },

    pageSizeOptions: [
        5,
        10,
        20,
        25,
        50,
        100
    ],

    defaultArticleOrder: 100,
    defaultPodcastOrder: 0,
    defaultPodcastEpisodeNumber: 1,

    defaultSettingsUsersPageSize: 20
};
