import React from "react";
import Select from 'react-select';
import {Constants} from "../../common/Constants";
import {ErrorUtility} from "../../common/ErrorUtility";
import Switch from "rc-switch";
import {ValidationUtility} from "../../common/ValidationUtility";
import {CommonModal} from "../../common/CommonModal";
import {PortalApi} from "../../common/PortalApi";
import {LoadingIndicator} from "../../common/LoadingIndicator";

export const EditUserModal = class extends React.Component {
    constructor (props) {
        super(props);
        const user = props.userForEdit;

        this.state = {
            user: {
                user_id: user ? user.id : '',
                active: user ? user.active : true,
                role_id: user ? user.user_role.role.id : null,
                first_name: user ? user.first_name : '',
                last_name: user ? user.last_name : '',
                email: user ? user.email : '',
                password: '',
                password_confirm: '',
            },
            showPasswordFields: false,
            loading: false
        };
    }

    loadingSetter = (value = false, callback = () => {}) => {
        this.setState({
            loading: value
        }, callback);
    }

    handleChange = (event) => {
        let state = this.state;
        const hashFields = event.target.name.split(".");
        state.user = this.updateHashItem(state.user, hashFields, 0, event.target.value);
        this.setState(state);
    }

    updateHashItem = (result, hash, index, newVal) => {
        const key = hash[index];

        result[key] = (hash.length === index + 1)
            ? newVal
            : this.updateHashItem(result[key], hash, index + 1, newVal);

        return result;
    }

    onActiveChange = () => {
        let newState = this.state;
        newState.user.active = !newState.user.active;
        this.setState(newState);
    }

    validateUser = function () {
        const {user} = this.state;
        let errorMessages = [];
        const {
            first_name,
            last_name,
            email,
            role_id
        } = this.state.user;

        if (!first_name || !first_name.length) {
            errorMessages.push('Set First Name');
        }

        if (!last_name || !last_name.length) {
            errorMessages.push('Set Last Name');
        }

        if (!role_id) {
            errorMessages.push('Select User Role');
        }

        if (!email || !email.length) {
            errorMessages.push('Set Email');
        } else if (!ValidationUtility.validateEmail(email)) {
            errorMessages.push('Email format is not valid');
        }

        errorMessages.push(
            ...new Set(this.validatePasswords())
        );

        if (errorMessages.length) {
            errorMessages.map( (message) => {
                ErrorUtility.handleError(false, message);
            });
        }

        return !errorMessages.length;
    }

    validatePasswords = () => {
        const {user} = this.state;
        let errorMessages = [];
        const isPasswordShowOrAddModal = !user.user_id || this.state.showPasswordFields;

        if (!isPasswordShowOrAddModal) {
            return errorMessages;
        }

        if (!user.password) {
            errorMessages.push('Password required')
        }

        if (!ValidationUtility.validatePassword(user.password)) {
            errorMessages.push(`Password does not meet all the requirements: ${user.password}`);
        } else if (!user.password_confirm) {
            errorMessages.push('Password confirm required');
        } else if (user.password !== user.password_confirm) {
            errorMessages.push('Passwords do not match.');
        }

        return errorMessages;
    }

    getUserDataForSubmission = () => {
        const user = this.state.user;
        const isPasswordShowOrAddModal = !user.user_id || this.state.showPasswordFields;
        let userData = {
            user_id: user ? user.user_id : null,
            active: user ? user.active : true,
            role_id: user ? user.role_id : null,
            first_name: user ? user.first_name : '',
            last_name: user ? user.last_name : '',
            email: user ? user.email : ''
        }
        if (isPasswordShowOrAddModal) {
            userData.password = user.password;
            userData.password_confirm = user.password_confirm;
        }
        return userData;
    }

    handleSave = () => {
        const isValid = this.validateUser();
        if (!isValid) {
            return;
        }

        this.loadingSetter(true, async () => {
            try {
                const userData = this.getUserDataForSubmission();
                const response = await PortalApi.updateUser(userData);
                console.log(":response: ", response);
                toastr.success(response.message);
                this.loadingSetter(false);
                this.props.afterSubmitListener();
            } catch (err) {
                this.loadingSetter(false);
                ErrorUtility.handleError(err, 'An error occurred while updating user profile');
            }
        });
    }

    handleTogglePasswordFields = () => {
        this.setState({
            showPasswordFields: !this.state.showPasswordFields
        });
    }

    getRoleOptions = () => {
        if (!this.props.roles || !this.props.roles.length) {
            return null;
        }
        let roleOptions = [];
        this.props.roles.map( (role) => {
            roleOptions.push({label: role.name, value: role.id});
        });
        return roleOptions;
    }

    onRoleSelect = (role) => {
        let state = this.state;
        state.user.role_id = role.value;
        this.setState(state);
    }

    getRoleOptionIndexByValue = (roleId) => {
        const options = this.getRoleOptions();
        return options.findIndex(option => option.value == roleId);
    }

    render = () => {
        const isUserEdit = this.props.userForEdit;
        const roleOptions = this.getRoleOptions();
        const selectedRoleOptionIndex = this.getRoleOptionIndexByValue(this.state.user.role_id);

        return (
            <CommonModal
                modalTitle={(isUserEdit ? 'Edit' : 'Add New') + ' User'}
                onClose={this.props.toggleModal}
            >
                {this.state.loading &&
                    <LoadingIndicator />
                }
                <div className="modal-row">
                    <div>
                        <label>First Name</label>
                        <input
                            type="text"
                            name="first_name"
                            placeholder="First Name"
                            value={this.state.user.first_name}
                            onChange={this.handleChange}
                            onBlur={(e) => {
                                this.handleChange({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.value ? e.target.value.trim() : null
                                    }
                                });
                            }}
                            maxLength={100}
                        />
                    </div>
                    <div>
                        <label>Last Name</label>
                        <input
                            type="text"
                            placeholder="Last Name"
                            name="last_name"
                            value={this.state.user.last_name}
                            onChange={this.handleChange}
                            onBlur={(e) => {
                                this.handleChange({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.value ? e.target.value.trim() : null
                                    }
                                });
                            }}
                            maxLength={100}
                        />
                    </div>
                </div>
                <div className="modal-row">
                    <div>
                        <label>User Role</label>
                        <Select
                            className="select"
                            placeholder="Select Role"
                            isMulti={false}
                            isClearable={false}
                            isSearchable={false}
                            autosize={true}
                            autoBlur={true}
                            value={roleOptions[selectedRoleOptionIndex]}
                            options={roleOptions}
                            onChange={(e) => {this.onRoleSelect(e);}}
                        />
                    </div>
                    <div>
                        <label>Email</label>
                        <input
                            type="text"
                            placeholder="Email"
                            name="email"
                            value={this.state.user.email}
                            onChange={this.handleChange}
                            maxLength={100}
                        />
                    </div>
                </div>
                {!this.state.user.user_id || this.state.showPasswordFields
                    ?   <div>
                            <div className="modal-row">
                                <div>
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        name="password"
                                        value={this.state.user['password']}
                                        onChange={this.handleChange}
                                        maxLength={255}
                                    />
                                </div>
                                <div>
                                    <label>Password Confirm</label>
                                    <input
                                        type="password"
                                        placeholder="Password confirm"
                                        name="password_confirm"
                                        value={this.state.user['password_confirm']}
                                        onChange={this.handleChange}
                                        maxLength={255}
                                    />
                                </div>
                            </div>
                            <div className="modal-row">
                                <div />
                                <div>
                                    <div>Your password should contain:</div>
                                    <ul>
                                        <li>At least one digit</li>
                                        <li>At least one lower case letter</li>
                                        <li>At least one upper case letter</li>
                                        <li>Be at least 8 characters long</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    :   <div className="modal-row">
                            <div>
                                <button className="change-password-btn" onClick={this.handleTogglePasswordFields}>Change Password</button>
                            </div>
                            <div />
                        </div>
                }
                <div className="modal-row alt-controls">
                    <div className="alt-controls-column first">
                        <div className="switch-wrapper">
                            <label>
                                <Switch
                                    height={20}
                                    width={40}
                                    checked={this.state.user.active}
                                    onChange={this.onActiveChange}
                                />
                                <span>Active</span>
                            </label>
                        </div>
                    </div>
                    <div className="alt-controls-column last">
                        {isUserEdit &&
                            <>
                                {/*<button className="delete compact mb-0" onClick={() => {this.props.toggleClearLogsModal(this.state.user.user_id)}}>Delete User Logs</button>*/}
                                <button className="delete compact mb-0 ml-3" onClick={() => {this.props.toggleDeleteUserModal(this.state.user.user_id)}}>Delete User Account</button>
                            </>
                        }
                    </div>
                </div>
                <div className="controls">
                    <button className="cancel" onClick={this.props.toggleModal}>Cancel</button>
                    <button onClick={this.handleSave}>Save and Close</button>
                </div>
            </CommonModal>
        );
    }
};
