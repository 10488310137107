import React from "react";
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import {Constants} from "./Constants";
import {NavLink} from "react-router-dom";

export const FooterMenu = withRouter(class extends React.Component {
    static contextTypes = {
        navigator: PropTypes.object
    }

    render = () => {
        return (
            <div className='footer'>
                <NavLink to={Constants.routes.settingsUsers} activeClassName={'active'}>Users</NavLink>
                <NavLink to={Constants.routes.settingsArticles} activeClassName={'active'}>Curated Articles</NavLink>
                <NavLink to={Constants.routes.settingsPodcasts} activeClassName={'active'}>Curated Podcasts</NavLink>
                <NavLink to={Constants.routes.settingsQuizQuestions} activeClassName={'active'}>Quiz Questions</NavLink>
            </div>
        );
    }
});
