import React from "react";

export const TextUtility = {
    notEmpty: function (value) {
        return value != null && (value.trim ? value.trim().length > 0 : true);
    },

    capitalizeSafe: function (text) {
        if (text && text.trim().length > 0) {
            return text.toUpperCase();
        } else {
            return text;
        }
    },

    normalizeTextForDBSafe: function (str) {
        return str.replace(/[\0\x08\x09\x1a\n\r"'\\\%]/g, function (char) {
            switch (char) {
                case "\0":
                    return "\\0";
                case "\x08":
                    return "\\b";
                case "\x09":
                    return "\\t";
                case "\x1a":
                    return "\\z";
                case "\n":
                    return "\\n";
                case "\r":
                    return "\\r";
                case "\"":
                case "'":
                case "\\":
                case "%":
                    return "\\" + char; // prepends a backslash to backslash, percent, and double/single quotes
            }
        })
    },

    truncateText: function (str, n = 100) {
        return (str.length > n) ? str.substr(0, n-1) + '...' : str;
    },

    normalizeTextFromInput: function (text = null) {
        return text ? text.trim() : null;
    },
};
