import React from "react";

export const AccessRestricted = class extends React.Component {
    render = () => {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="access-restricted">
                            <h1 className="access-restricted-text">Access Restricted</h1>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};
