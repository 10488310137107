import React from "react";
import {displayForPermissions} from "../../common/AuthorizedComponent";
import {Constants} from "../../common/Constants";
import {UrlUtility} from "../../common/UrlUtility";
import {ErrorUtility} from "../../common/ErrorUtility";
import {ReactTable} from "../../common/ReactTable";
import {PortalApi} from "../../common/PortalApi";
import {EditPodcastModal} from "./EditPodcastModal";
import {DeletePodcastModal} from "./DeletePodcastModal";
import {Header} from "../../common/Header";
import {FooterMenu} from "../../common/FooterMenu";

export const PodcastsPage = displayForPermissions(
    [Constants.permissions.settingsManage],
    class extends React.Component {
        constructor(props) {
            super(props);

            const urlParams = UrlUtility.getParamsFromUrl(props.location.search);
            const filter = UrlUtility.getParamValue(urlParams, "filter", []);
            const sort = UrlUtility.getParamValue(urlParams, "sort", []);
            const pageSize = UrlUtility.getParamValue(urlParams, "pageSize", Constants.defaultSettingsUsersPageSize);

            this.state = {
                loading: false,
                sort: sort,
                page: 0,
                filter: filter,
                pageSize: pageSize,
                podcastList: [],
                podcastStyleList: [],
                showPodcastAddPopup: false,
                showPodcastDeletePopup: false,
            };
        }

        componentDidMount = () => {
            this.navigateToFilteredPage();
        }

        loadingSetter = (value = false, callback = () => {}) => {
            this.setState({
                loading: value
            }, callback);
        }

        refreshPageData = (state = null) => {
            this.setState(oldState => {
                return {
                    loading: true,
                    filter: state ? state.filter : oldState.filter,
                    sort: state ? state.sort : oldState.sort,
                    page: state ? state.page : oldState.page,
                    pageSize: state ? state.pageSize : oldState.pageSize
                }
            }, async () => {
                const params = {
                    filter: this.state.filter,
                    sort: this.state.sort,
                    page: this.state.page,
                    pageSize: this.state.pageSize,
                    showAll: true,
                };
                try {
                    const podcastsResponse = await PortalApi.fetchPodcasts(params);
                    const podcastStylesResponse = await PortalApi.fetchPodcastStyles();

                    this.setState({
                        loading: false,
                        podcastList: podcastsResponse.podcasts,
                        podcastStyleList: podcastStylesResponse.podcast_styles,
                        filter: this.state.filter,
                        sort: this.state.sort,
                        page: this.state.page,
                        pageSize: this.state.pageSize,
                        pages: Math.ceil(parseInt(podcastsResponse.count) / this.state.pageSize)
                    }, () => {
                        this.navigateToFilteredPage();
                    });

                } catch (err) {
                    ErrorUtility.handleError(err, 'Internal server error: Can&#039;t fetch podcasts list');
                    this.setState({loading: false});
                };
            });
        }

        navigateToFilteredPage = () => {
            const {filter, sort, pageSize} = this.state;

            let query = {};
            if (filter != null) {
                query['filter'] = filter;
            }
            if (sort != null) {
                query['sort'] = sort;
            }
            if (pageSize != null) {
                query['pageSize'] = pageSize;
            }

            this.props.history.push('/settings/podcasts?' + $.param(query));
        }

        getPodcastByIdFromList = (id) => {
            if (!this.state.podcastList || !id) {
                return null;
            }
            return this.state.podcastList.find((podcast) => {
                if (podcast.id == id) {
                    return podcast;
                }
            });
        }

        togglePodcastAddPopup = (podcastId = null) => {
            this.setState ({
                podcastForEditId: podcastId,
                showPodcastAddPopup: !this.state.showPodcastAddPopup
            });
        }

        afterPodcastSave = () => {
            this.setState({
                showPodcastAddPopup: false,
                podcastForEditId: null
            }, () => {this.refreshPageData();});
        }

        togglePodcastDeletePopup = (podcastId = null) => {
            this.setState ({
                podcastForEditId: podcastId,
                showPodcastDeletePopup: !this.state.showPodcastDeletePopup
            });
        }

        onPodcastDelete = (podcastId) => {
            this.loadingSetter(true, () => {
                PortalApi.deletePodcast(podcastId)
                    .then((response) => {
                        toastr.success(response.message);
                        this.loadingSetter(false);
                        this.togglePodcastDeletePopup();
                        this.refreshPageData();
                    })
                    .catch((error) => {
                        this.loadingSetter(false);
                        ErrorUtility.handleError(error, 'An error occurred while delete a podcast.');
                    });
            });
        }

        getTableColumns = () => {
            const columns = [{
                Header: () => (
                    <span>
                        Podcast Name
                            <img
                                src="/static/image/icon-filter-down.svg"
                            />
                    </span>
                ),
                accessor: 'podcast_name'
            }, {
                Header: () => (
                    <span>
                        Episode #
                        <img
                            src="/static/image/icon-filter-down.svg"
                        />
                    </span>
                ),
                accessor: 'episode_number'
            }, {
                Header: () => (
                    <span>
                        Episode Name
                        <img
                            src="/static/image/icon-filter-down.svg"
                        />
                    </span>
                ),
                accessor: 'episode_name'
            }, {
                Header: () => (
                    <span>
                        Order
                        <img
                            src="/static/image/icon-filter-down.svg"
                        />
                    </span>
                ),
                accessor: 'order'
            }, {
                Header: () => (
                    <span>
                        Style
                        <img
                            src="/static/image/icon-filter-down.svg"
                        />
                    </span>
                ),
                accessor: 'podcast_style.name',
                disableFilters: true
            }, {
                accessor: 'id',
                Cell: ({row}) => (
                    <button onClick={() => {this.togglePodcastDeletePopup(row.original.id);}}>
                        <img className="img-responsive" src='/static/image/icon-delete.svg' alt='icon-delete' />
                    </button>
                ),
                disableFilters: true
            }];

            return columns;
        }

        render = () => {
            return (
                <div className="page-wrapper podcasts-page">
                    <Header
                        history={this.props.history}
                    />
                    <div className="page-heading">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="page-title">Curated Podcasts</h1>
                                    <p>
                                        Podcasts display in descending order in the app.
                                        <br/>
                                        The top 4 items in the order are displayed on the homescreen.
                                    </p>
                                    <div className="page-heading-controls">
                                        <button className="compact" onClick={() => {this.togglePodcastAddPopup();}}>Add New Podcast</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ReactTable
                                        data={this.state.podcastList}
                                        columns={this.getTableColumns()}
                                        className={'podcasts-list-content'}
                                        pages={this.state.pages}
                                        loading={this.state.loading}
                                        defaultSorted={this.state.sort}
                                        defaultFiltered={this.state.filter}
                                        defaultPageSize={this.state.pageSize}
                                        onFetchData={(state) => this.refreshPageData(state)}
                                        getTdProps={({row, column}) => {
                                            return {
                                                onClick: () => {
                                                    if(column.id !== "id") {
                                                        this.togglePodcastAddPopup(row.original.id);
                                                    }
                                                }
                                            };
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {this.state.showPodcastAddPopup &&
                            <EditPodcastModal
                                toggleModal={this.togglePodcastAddPopup}
                                afterSubmitListener={this.afterPodcastSave}
                                podcastForEdit={this.getPodcastByIdFromList(this.state.podcastForEditId)}
                                podcastStyleList={this.state.podcastStyleList}
                            />
                        }
                        {this.state.showPodcastDeletePopup &&
                            <DeletePodcastModal
                                loading={this.state.loading}
                                toggleModal={this.togglePodcastDeletePopup}
                                afterSubmitListener={this.onPodcastDelete}
                                podcastForEdit={this.state.podcastForEditId}
                            />
                        }
                    </div>
                    <FooterMenu />
                </div>
            )
        }
    }
);