import React from "react";
import {Link} from "react-router-dom";
import {withRouter} from "react-router";
import {AuthUtility} from "../common/AuthUtility";
import {TextUtility} from "../common/TextUtility";
import {ErrorUtility} from "../common/ErrorUtility";
import {ValidationUtility} from "../common/ValidationUtility";
import {Constants} from "../common/Constants";
import {PortalApi} from "../common/PortalApi";
import {Header} from "../common/Header";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        };
    }

    componentDidMount = () => {
        if (AuthUtility.isLoggedIn()) {
            this.props.history.push(Constants.routes.settingsUsers);
        }
    }

    handleChange = (event) => {
        let newState = this.state;
        if (!newState.hasOwnProperty(event.target.name)) {
            return;
        }
        newState[event.target.name] = event.target.value;
        this.setState(newState);
    }

    validate = () => {
        const {
            email,
            password
        } = this.state;

        let errorMessages = [];

        if (!ValidationUtility.validateEmail(email)) {
            errorMessages.push(`Invalid email: ${email}.`);
        }

        if (!ValidationUtility.validatePassword(password)) {
            errorMessages.push(`Password does not meet all the requirements: ${password}`);
        }

        ValidationUtility.showErrorMessages(errorMessages);

        return !errorMessages.length;
    }

    handleSubmit = async () => {
        if (!this.validate()) {
            return;
        }

        const userData = {
            email: this.state.email,
            password: this.state.password
        };

        try {
            const response = await PortalApi.login(userData);
                if (
                    !response.hasOwnProperty("user_data") ||
                    !response.user_data.hasOwnProperty("authentication_token")
                    || !response.user_data.authentication_token
                ) {
                    ErrorUtility.handleError(null, 'An error occurred while sign in');
                } else {
                    if (!response.user_data.user_permissions.some( userPermission => userPermission.name == Constants.permissions.settingsManage)) {
                        ErrorUtility.handleError(null, "Your user role does not have permission to access a web portal");
                        return;
                    }
                    AuthUtility.setUserDataToCookie(response.user_data);
                    this.props.history.push(Constants.routes.settingsUsers);
                }
        } catch (err) {
            ErrorUtility.handleError(err, `An error occurred attempting to sign in: ${err}`);
        }
    }

    render = () => {
        const {email, password} = this.state;

        return (
            <div className="page-wrapper center-aligned">
                <Header
                    history={this.props.history}
                />
                <div className="page-heading">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="page-title">Login</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content center-aligned">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="form">
                                    <div className="form-row">
                                        <div>
                                            <label>Email</label>
                                            <input name="email" type="email" placeholder="Email" value={email} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div>
                                            <label>Password</label>
                                            <input name="password" type="password" placeholder="Password" value={password} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div>
                                            <Link to={Constants.routes.register}>Register</Link>
                                        </div>
                                        <div className="right-aligned">
                                            <Link to={Constants.routes.sendRestorePasswordCode}>Forgot Password</Link>
                                        </div>
                                    </div>
                                    <div className="form-row controls">
                                        <div>
                                            <button type="submit" onClick={this.handleSubmit}>
                                                Sign In
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default withRouter(Login);
