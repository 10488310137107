import React from "react";
import {AuthUtility} from "./AuthUtility";
import {AccessRestricted} from "../access-restricted/AccessRestricted";

let AuthorizedComponent;

export function displayForPermissions(permissions, Component) {
    AuthorizedComponent = class extends React.Component{
        constructor(props) {
            super(props);

            this.state = {
                showComponent: false
            }
        }

        componentDidMount = () => {
            if (AuthUtility.userHasPermissions(permissions)) {
                this.setState({showComponent: true});
            }
        }

        render = () => {
            if (this.state.showComponent) {
                return (
                    <Component {...this.props} {...this.state} {...this.context} />
                );
            } else {
                return <AccessRestricted />;
            }
        }
    }

    return AuthorizedComponent;
}
