import React from "react";

export class NotFoundPage extends React.Component {
    render = () => {
        return (
            <div>
                <h1 className="error-code">404</h1>
                <div className="dialog">
                    <div>
                        <h1>The page you were looking for doesn't exist.</h1>
                        <p>You may have mistyped the address or the page may have moved.</p>
                    </div>
                    <p>If you are the application owner check the logs for more information.</p>
                </div>
            </div>
        );
    }
};
