import React from 'react';
import {PromiseUtility} from "./PromiseUtility";
import Cookies from 'js-cookie';
let _ = require('underscore');

export const ApiUtility = {
    apiFetch: function (url, options, asHTML) {
        const token = Cookies.get("token");
        let extendedHeaders = (options && options.headers) || {};
        extendedHeaders['wwwauthenticate'] = '' + token;

        return new Promise( (resolve, reject) => {
            fetch (
                url,
                _.extend(options || {}, {headers: extendedHeaders})
            )
                .then( async (rawResponse) => {
                    if ([404, 403, 401, 204].includes(rawResponse.status)) {
                        if (rawResponse.message) {
                            throw new Error((rawResponse.message));
                        } else {
                            throw new Error((rawResponse.statusText));
                        }
                    } else {
                        let responseData;

                        if (asHTML) {
                            responseData = await rawResponse.text();
                        } else {
                            responseData =  await rawResponse.json();
                        }

                        return {responseData: responseData, rawResponse: rawResponse}
                    }
                })
                .then(({responseData, rawResponse}) => {
                    if (rawResponse.status == 500) {
                        console && console.log("A " + rawResponse.status + " occurred during an API call: ", rawResponse);
                        console && console.log("An error occurred during an API call: ", arguments);

                        if (responseData.message) {
                            throw new Error((responseData.message));
                        }

                        throw new Error((rawResponse.statusText));
                    }

                    resolve(responseData);
                })
                .catch( (error) => {
                    console && console.log("An error occurred during an API call: ", arguments);
                    reject((error));
                });
        });
    }
};
