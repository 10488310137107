import React from "react";
import {displayForPermissions} from "../../common/AuthorizedComponent";
import {Constants} from "../../common/Constants";
import {UrlUtility} from "../../common/UrlUtility";
import {ErrorUtility} from "../../common/ErrorUtility";
import {ReactTable} from "../../common/ReactTable";
import {PortalApi} from "../../common/PortalApi";
import {EditUserModal} from "./EditUserModal";
import Switch from "rc-switch";
import {Header} from "../../common/Header";
import {FooterMenu} from "../../common/FooterMenu";
import {ConfirmationModal} from "../../common/ConfirmationModal";
import {DeleteUserModal} from "./DeleteUserModal";

export const UsersPage = displayForPermissions(
    [Constants.permissions.settingsManage],
    class extends React.Component {
        constructor(props) {
            super(props);

            const urlParams = UrlUtility.getParamsFromUrl(props.location.search);
            const filter = UrlUtility.getParamValue(urlParams, "filter", []);
            const sort = UrlUtility.getParamValue(urlParams, "sort", []);
            const pageSize = UrlUtility.getParamValue(urlParams, "pageSize", Constants.defaultSettingsUsersPageSize);

            this.state = {
                loading: false,
                sort: sort,
                page: 0,
                filter: filter,
                pageSize: pageSize,
                userList: [],
                roles: [],
                showUserAddPopup: false,
                showDeleteUserModal: false,
                showClearLogsModal: false,
            };
        }

        componentDidMount = () => {
            this.navigateToFilteredPage();
        }

        refreshPageData = (state = null) => {
            this.setState(oldState => {
                return {
                    loading: true,
                    filter: state ? state.filter : oldState.filter,
                    sort: state ? state.sort : oldState.sort,
                    page: state ? state.page : oldState.page,
                    pageSize: state ? state.pageSize : oldState.pageSize
                }
            }, async () => {
                const params = {
                    filter: this.state.filter,
                    sort: this.state.sort,
                    page: this.state.page,
                    pageSize: this.state.pageSize,
                    showAll: true,
                };
                try {
                    const usersResponse = await PortalApi.fetchUsers(params);
                    const rolesResponse = await PortalApi.fetchRoles();

                    this.setState({
                        loading: false,
                        userList: usersResponse.users,
                        roles: rolesResponse.roles,
                        filter: this.state.filter,
                        sort: this.state.sort,
                        page: this.state.page,
                        pageSize: this.state.pageSize,
                        pages: Math.ceil(parseInt(usersResponse.count) / this.state.pageSize)
                    }, () => {
                        this.navigateToFilteredPage();
                    });

                } catch (err) {
                    ErrorUtility.handleError(err, 'Internal server error: Can&#039;t fetch users list.');
                    this.setState({loading: false});
                };
            });
        }

        loadingSetter = (value = false, callback = () => {}) => {
            this.setState({
                loading: value
            }, callback);
        }

        navigateToFilteredPage = () => {
            const {filter, sort, pageSize} = this.state;

            let query = {};
            if (filter != null) {
                query['filter'] = filter;
            }
            if (sort != null) {
                query['sort'] = sort;
            }
            if (pageSize != null) {
                query['pageSize'] = pageSize;
            }

            this.props.history.push('/settings/users?' + $.param(query));
        }

        toggleUser = (userId, isActive = true) => {
            this.loadingSetter(true, async () => {
                try {
                    const response = await PortalApi.updateUser({
                        user_id: userId,
                        active: isActive
                    });

                    if (typeof response.message !== "undefined" && response.message) {
                        toastr.success(response.message);
                    } else {
                        toastr.success("User updated");
                    }

                    this.loadingSetter(false, this.refreshPageData);
                } catch (err) {
                    ErrorUtility.handleError(err, 'An error occurred while updating user profile');
                    this.loadingSetter(false);
                }
            });
        }

        getUserByIdFromList = (id) => {
            if (!this.state.userList || !id) {
                return null;
            }
            return this.state.userList.find((user) => {
                if (user.id == id) {
                    return user;
                }
            });
        }

        toggleUserAddPopup = (userId = null) => {
            this.setState ({
                userForEditId: userId,
                showUserAddPopup: !this.state.showUserAddPopup
            });
        }

        afterUserSave = () => {
            this.setState({
                showUserAddPopup: false,
                userForEditId: null
            }, () => {this.refreshPageData();});
        }

        toggleDeleteUserModal = (userId = null, callback = null) => {
            this.setState(state => {
                return {
                    activeUserId: userId,
                    showDeleteUserModal: !state.showDeleteUserModal
                };
            }, () => {
                if (typeof callback == "function") {
                    callback();
                }
            });
        }

        toggleClearLogsModal = (userId = null, callback = null) => {
            this.setState(state => {
                return {
                    activeUserId: userId,
                    showClearLogsModal: !state.showClearLogsModal
                };
            }, () => {
                if (typeof callback == "function") {
                    callback();
                }
            });
        }

        deleteUser = () => {
            const activeUserId = this.state.activeUserId;
            this.toggleDeleteUserModal(null, () => {
                this.loadingSetter(true, async () => {
                    const response = await PortalApi.deleteUser(activeUserId);
                    console.log(":response: ", response);
                    toastr.success(response.message);
                    this.loadingSetter(false, this.refreshPageData);
                });
                this.toggleUserAddPopup();
            });
        }

        clearUserLogs = () => {
            const activeUserId = this.state.activeUserId;
            this.toggleClearLogsModal(null, () => {
                this.loadingSetter(true, async () => {
                    const response = await PortalApi.deleteUserLogs(activeUserId);
                    console.log(":response: ", response);
                    toastr.success(response.message);
                    this.loadingSetter(false, this.refreshPageData);
                });
                this.toggleUserAddPopup();
            });
        }

        getTableColumns = () => {
            const columns = [{
                Header: () => (
                    <span>
                        First Name
                            <img
                                src="/static/image/icon-filter-down.svg"
                            />
                    </span>
                ),
                accessor: 'first_name'
            }, {
                Header: () => (
                    <span>
                        Last Name
                        <img
                            src="/static/image/icon-filter-down.svg"
                        />
                    </span>
                ),
                accessor: 'last_name'
            }, {
                Header: () => (
                    <span>
                        Email
                        <img
                            src="/static/image/icon-filter-down.svg"
                        />
                    </span>
                ),
                accessor: 'email'
            }, {
                Header: () => (
                    <span>
                        Role
                        <img
                            src="/static/image/icon-filter-down.svg"
                        />
                    </span>
                ),
                accessor: 'user_role.role.name'
            }, {
                accessor: 'id',
                width: 55,
                Cell: ({row}) => (
                    <div className="column_controls">
                        <Switch
                            height={20}
                            width={40}
                            checked={!!row.original.active}
                            onChange={() => {
                                this.toggleUser(row.original.id, !row.original.active);
                            }}
                        />
                    </div>
                ),
                disableFilters: true,
            }];

            return columns;
        }

        render = () => {
            return (
                <div className="page-wrapper users-page">
                    <Header
                        history={this.props.history}
                    />
                    <div className="page-heading">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="page-title">Users</h1>
                                    <div className="page-heading-controls">
                                        <button className="compact" onClick={() => {this.toggleUserAddPopup();}}>Add New User</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ReactTable
                                        data={this.state.userList}
                                        columns={this.getTableColumns()}
                                        className={'users-list-content'}
                                        pages={this.state.pages}
                                        loading={this.state.loading}
                                        defaultSorted={this.state.sort}
                                        defaultFiltered={this.state.filter}
                                        defaultPageSize={this.state.pageSize}
                                        onFetchData={(state) => this.refreshPageData(state)}
                                        getTdProps={({row, column}) => {
                                            return {
                                                onClick: () => {
                                                    if(column.id !== "id") {
                                                        this.toggleUserAddPopup(row.original.id);
                                                    }
                                                }
                                            };
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {this.state.showUserAddPopup &&
                            <EditUserModal
                                toggleModal={this.toggleUserAddPopup}
                                roles={this.state.roles}
                                afterSubmitListener={this.afterUserSave}
                                userForEdit={this.getUserByIdFromList(this.state.userForEditId)}
                                showDeleteUserModal={this.state.showDeleteUserModal}
                                showClearLogsModal={this.state.showClearLogsModal}
                                toggleDeleteUserModal={this.toggleDeleteUserModal}
                                toggleClearLogsModal={this.toggleClearLogsModal}
                            />
                        }
                        {this.state.showDeleteUserModal &&
                            <DeleteUserModal
                                isChildModal={true}
                                loading={this.state.loading}
                                modalTitle={"Delete User with log history"}
                                toggleModal={this.toggleDeleteUserModal}
                                onSubmit={this.deleteUser}
                                confirmButtonText="Confirm Deletion"
                                description={<>You are about to <b><span className="red">delete</span></b> this user account and their data. This action cannot be reverted.</>}
                            />
                        }
                        {this.state.showClearLogsModal &&
                            <DeleteUserModal
                                isChildModal={true}
                                loading={this.state.loading}
                                modalTitle={"Clear user's logs"}
                                toggleModal={this.toggleClearLogsModal}
                                onSubmit={this.clearUserLogs}
                                confirmButtonText="Confirm Deletion"
                                description={<>You are about to <b><span className="red">delete</span></b> all quiz data for this user. This action cannot be reverted.</>}
                            />
                        }
                    </div>
                    <FooterMenu />
                </div>
            )
        }
    }
);