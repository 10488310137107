import React from "react";
import {Constants} from "./Constants";

export const CommonModal = class extends React.Component {
    constructor (props) {
        super(props);

        this.state = {};
    }

    render = () => {
        return (
            <div
                className="common-modal-overlay"
                style={{backgroundColor: this.props.isChildModal ? 'rgba(0,0,0, .15)' : 'rgba(0,0,0, .75)'}}
            >
                <div
                    className={"common-modal overlay-style-loader-wrapper "  + (this.props.modalType ? this.props.modalType : '')}
                >
                    <div className="common-modal-header">
                        <div className="common-modal-header-text">
                            {this.props.modalTitle}
                        </div>
                        <button className="btn-close" onClick={this.props.onClose}>
                            {this.props.modalType === Constants.modal.type.delete
                                ?   <img src='/static/image/icon-modal-close-red.svg' />
                                :   <img src='/static/image/icon-modal-close.svg' />
                            }
                        </button>
                    </div>
                    <div className="common-modal-content">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
};
