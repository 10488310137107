import React from "react";
import Select from 'react-select';
import {Constants} from "../../common/Constants";
import {ErrorUtility} from "../../common/ErrorUtility";
import Switch from "rc-switch";
import {ValidationUtility} from "../../common/ValidationUtility";
import {PortalApi} from "../../common/PortalApi";
import {CommonModal} from "../../common/CommonModal";
import {LoadingIndicator} from "../../common/LoadingIndicator";

export const EditQuizQuestionModal = class extends React.Component {
    constructor (props) {
        super(props);
        const quizQuestion = props.quizQuestionForEdit;

        this.state = {
            quizQuestion: {
                quiz_question_id: quizQuestion ? quizQuestion.id : '',
                active: quizQuestion ? quizQuestion.active : true,
                title: quizQuestion ? quizQuestion.title : '',
                description: quizQuestion ? quizQuestion.description : '',
                order: quizQuestion ? parseInt(quizQuestion.order) : 0,
                correct_answer_yes: quizQuestion ? !!quizQuestion.correct_answer_yes : true,
                is_bonus: quizQuestion ? !!quizQuestion.is_bonus : false
            },
            loading: false
        };
    }

    loadingSetter = (value = false, callback = () => {}) => {
        this.setState({
            loading: value
        }, callback);
    }

    handleChange = (event) => {
        let state = this.state;
        const hashFields = event.target.name.split(".");
        state.quizQuestion = this.updateHashItem(state.quizQuestion, hashFields, 0, event.target.value);
        this.setState(state);
    }

    updateHashItem = (result, hash, index, newVal) => {
        const key = hash[index];

        result[key] = (hash.length === index + 1)
            ? newVal
            : this.updateHashItem(result[key], hash, index + 1, newVal);

        return result;
    }

    onActiveChange = () => {
        let newState = this.state;
        newState.quizQuestion.active = !newState.quizQuestion.active;
        this.setState(newState);
    }

    onCorrectAnswerYesChange = (value) => {
        let newState = this.state;
        newState.quizQuestion.correct_answer_yes = value;
        this.setState(newState);
    }

    onIsBonusChange = () => {
        let newState = this.state;
        newState.quizQuestion.is_bonus = !newState.quizQuestion.is_bonus;
        this.setState(newState);
    }

    validate = function () {
        let errorMessages = [];
        const {
            title,
            description
        } = this.state.quizQuestion;

        if (!title || !title.length) {
            errorMessages.push('Set Title');
        }

        if (!description || !description.length) {
            errorMessages.push('Set Description');
        }

        if (errorMessages.length) {
            errorMessages.map( (message) => {
                ErrorUtility.handleError(false, message);
            });
        }

        return !errorMessages.length;
    }

    getQuizQuestionDataForSubmission = () => {
        const quizQuestion = this.state.quizQuestion;
        let quizQuestionData = {
            quiz_question_id: quizQuestion ? quizQuestion.quiz_question_id : null,
            active: quizQuestion ? quizQuestion.active : true,
            title: quizQuestion ? quizQuestion.title : null,
            description: quizQuestion ? quizQuestion.description : '',
            order: quizQuestion ? parseInt(quizQuestion.order) : 0,
            correct_answer_yes: quizQuestion ? !!quizQuestion.correct_answer_yes : true,
            is_bonus: quizQuestion ? !!quizQuestion.is_bonus : false
        }
        return quizQuestionData;
    }

    handleSave = () => {
        const isValid = this.validate();
        if (!isValid) {
            return;
        }

        this.loadingSetter(true, async () => {
            try {
                const quizQuestionData = this.getQuizQuestionDataForSubmission();
                const response = await PortalApi.updateQuizQuestion(quizQuestionData);

                toastr.success(response.message);
                this.loadingSetter(false);
                this.props.afterSubmitListener();
            } catch (err) {
                this.loadingSetter(false);
                ErrorUtility.handleError(err, 'An error occurred while save quiz question');
            }
        });
    }

    render = () => {
        const isQuestionEdit = this.props.quizQuestionForEdit;
        const {quizQuestion} = this.state;

        return (
            <CommonModal
                modalTitle={(isQuestionEdit ? 'Edit' : 'Add New') + ' Quiz Question'}
                onClose={this.props.toggleModal}
            >
                {this.state.loading &&
                    <LoadingIndicator />
                }
                <div className="modal-row">
                    <div className="w-full">
                        <label>Question</label>
                        <textarea
                            className="accent"
                            name="title"
                            maxLength={256}
                            value={quizQuestion.title}
                            onChange={this.handleChange}
                            onBlur={(e) => {
                                this.handleChange({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.value ? e.target.value.trim() : null
                                    }
                                });
                            }}
                        />
                    </div>
                    <div className="last w-auto pt-4">
                        <div className="switch-wrapper">
                            <label>
                                <span>Active</span>
                                <Switch
                                    height={20}
                                    width={40}
                                    checked={this.state.quizQuestion.active}
                                    onChange={this.onActiveChange}
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div className="modal-row">
                    <div>
                        <label>Helper Text</label>
                        <textarea
                            name="description"
                            maxLength={2048}
                            value={quizQuestion.description}
                            onChange={this.handleChange}
                            onBlur={(e) => {
                                this.handleChange({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.value ? e.target.value.trim() : null
                                    }
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="modal-row">
                    <div>
                        <label>Order</label>
                        <input
                            type="text"
                            name="order"
                            pattern="[0-9]*"
                            placeholder="Order"
                            value={quizQuestion.order}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <div className="switch-wrapper">
                            <label>
                                <span className="ml-0">Bonus question</span>
                                <Switch
                                    height={20}
                                    width={40}
                                    checked={this.state.quizQuestion.is_bonus}
                                    onChange={this.onIsBonusChange}
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div className={"modal-row left-aligned"}>
                    <div className="w-auto">
                        <label>Correct Answer: </label>
                    </div>
                    <div className="w-auto">
                        <div className="checkbox-wrapper">
                            <label className="radio">
                                <input
                                    type="radio"
                                    name="correct_answer_yes"
                                    checked={this.state.quizQuestion.correct_answer_yes}
                                    onChange={() => {this.onCorrectAnswerYesChange(true);}}
                                />
                                <div className="checkmark"/>
                                Yes
                            </label>
                        </div>
                    </div>
                    <div className="w-auto">
                        <div className="checkbox-wrapper">
                            <label className="radio">
                                <input
                                    type="radio"
                                    name="correct_answer_yes"
                                    checked={!this.state.quizQuestion.correct_answer_yes}
                                    onChange={() => {this.onCorrectAnswerYesChange(false);}}
                                />
                                <div className="checkmark"/>
                                No
                            </label>
                        </div>
                    </div>
                </div>
                <div className="controls">
                    <button className="cancel" onClick={this.props.toggleModal}>Cancel</button>
                    <button onClick={this.handleSave}>Save and Close</button>
                </div>
            </CommonModal>
        );
    }
};
