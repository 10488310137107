import Cookies from 'js-cookie';
import {Constants} from "./Constants";
const _ = require('underscore');

export const AuthUtility = {
    // =========================================================================
    // Authentication functions
    // =========================================================================
    isLoggedIn: function () {
        const token = Cookies.get("token");
		if (token === undefined || !token) {
			return false;
		}
		return true;
    },

    isTokenExpired: function () {
        const tokenExpireFlag = Cookies.get("token_expire_flag");

		return tokenExpireFlag === undefined || !tokenExpireFlag
            ? true
            : false;
    },

    getUserDataFromCookie: function () {
        const userData = {
            authentication_token: Cookies.get("token") !== undefined ? Cookies.get("token") : null,
            first_name: Cookies.get("first_name") !== undefined ? Cookies.get("first_name") : null,
            last_name: Cookies.get("last_name") !== undefined ? Cookies.get("last_name") : null,
            email: Cookies.get("email") !== undefined ? Cookies.get("email") : null,
            user_role: Cookies.get("user_role") !== undefined ? Cookies.get("user_role") : null,
            user_id: Cookies.get("user_id") !== undefined ? Cookies.get("user_id") : null,
            user_permissions: Cookies.get("user_permissions") !== undefined ? Cookies.get("user_permissions") : [],
        };
        return userData;
    },

    setUserDataToCookie: function (userData) {
        if (userData.hasOwnProperty("authentication_token") && userData.authentication_token) {
            Cookies.set("token", userData.authentication_token);
        }
        if (userData.hasOwnProperty("first_name") && userData.first_name) {
            Cookies.set("first_name", userData.first_name);
        }
        if (userData.hasOwnProperty("last_name") && userData.last_name) {
            Cookies.set("last_name", userData.last_name);
        }
        if (userData.hasOwnProperty("email") && userData.email) {
            Cookies.set("email", userData.email);
        }
        if (userData.hasOwnProperty("user_role") && userData.user_role) {
            Cookies.set("user_role", userData.user_role);
        }
        if (userData.hasOwnProperty("user_id") && userData.user_id) {
            Cookies.set("user_id", userData.user_id);
        }
        if (userData.hasOwnProperty("user_permissions") && userData.user_permissions) {
            Cookies.set("user_permissions", userData.user_permissions);
        }
        if (userData.hasOwnProperty("email") && userData.email) {
            Cookies.set("email", userData.email);
        }
        Cookies.set(
            "token_expire_flag",
            true,
            {expires: parseFloat(Constants.tokenExpireTime)}
        );
        console.log(">> new token set: ", Cookies.get("token_expire_flag"));
    },

    resetUserDataInCookies: function () {
        Cookies.remove("token");
        Cookies.remove("first_name");
        Cookies.remove("last_name");
        Cookies.remove("email");
        Cookies.remove("token_refreshed_time");
    },

    //==========================================================================
    // Front-end functions
    //==========================================================================
    userHasPermissions: function (requiredPermissions) {
        const userPermissions = Cookies.getJSON("user_permissions");
        for (let i = 0; i < requiredPermissions.length; i++) {
            let requiredPermission = requiredPermissions[i];
            if (!userPermissions || !userPermissions.some( userPermission => userPermission.name == requiredPermission)) {
                return false;
            }
        }

        return true;
    },

    getCurrentUserId: function () {
        let userId = Cookies.get("userId");

        return userId ? userId : null;
    },

    userHasRole: function (roleName) {
        const currentRole = Cookies.get('role');

        if (typeof roleName === 'undefined' || roleName === null || !roleName) {
            return false;
        }

        if (currentRole.name === roleName) {
            return true;
        }

        return false;
    },

    getUserRoleName: function () {
        const currentRole = Cookies.get('role');

        if (!currentRole) {
            return '';
        }

        return currentRole.name;
    },
};
