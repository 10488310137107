import React from "react";
import Select from 'react-select'
import {Constants} from "../../common/Constants";
import {ErrorUtility} from "../../common/ErrorUtility";
import {CommonModal} from "../../common/CommonModal";
import {PortalApi} from "../../common/PortalApi";
import {ValidationUtility} from "../../common/ValidationUtility";
import {LoadingIndicator} from "../../common/LoadingIndicator";

export const EditPodcastModal = class extends React.Component {
    constructor (props) {
        super(props);
        const podcast = props.podcastForEdit;

        this.state = {
            podcast: {
                podcast_id: podcast ? podcast.id : '',
                podcast_name: podcast ? podcast.podcast_name : '',
                episode_name: podcast ? podcast.episode_name : '',
                episode_number: podcast ? podcast.episode_number : Constants.defaultPodcastEpisodeNumber,
                order: podcast ? podcast.order : Constants.defaultPodcastOrder,
                url: podcast ? podcast.url : '',
                android_url: podcast ? podcast.android_url : '',
                podcast_style_id: podcast ? podcast.podcast_style_id : null,
            },
            loading: false
        };
    }

    loadingSetter = (value = false, callback = () => {}) => {
        this.setState({
            loading: value
        }, callback);
    }

    handleChange = (event) => {
        let state = this.state;
        const hashFields = event.target.name.split(".");
        state.podcast = this.updateHashItem(state.podcast, hashFields, 0, event.target.value);
        this.setState(state);
    }

    updateHashItem = (result, hash, index, newVal) => {
        const key = hash[index];

        result[key] = (hash.length === index + 1)
            ? newVal
            : this.updateHashItem(result[key], hash, index + 1, newVal);

        return result;
    }

    validate = function () {
        let errorMessages = [];
        const {
            podcast_name,
            episode_name,
            podcast_style_id,
            url,
            android_url
        } = this.state.podcast;

        if (!podcast_name || !podcast_name.length) {
            errorMessages.push('Set Podcast Name');
        }

        if (!episode_name || !episode_name.length) {
            errorMessages.push('Set Episode Name');
        }

        if (!podcast_style_id) {
            errorMessages.push('Set Podcast Style');
        }

        if (!url || !ValidationUtility.validateUrl(url)) {
            errorMessages.push('Invalid Url For IOS');
        }

        if (!android_url || !ValidationUtility.validateUrl(android_url)) {
            errorMessages.push('Invalid Url For Android');
        }

        if (errorMessages.length) {
            errorMessages.map( (message) => {
                ErrorUtility.handleError(false, message);
            });
        }

        return !errorMessages.length;
    }

    getPodcastDataForSubmission = () => {
        const podcast = this.state.podcast;
        return {
            podcast_id: podcast ? podcast.podcast_id : null,
            podcast_name: podcast ? podcast.podcast_name : null,
            episode_name: podcast ? podcast.episode_name : null,
            episode_number: podcast ? podcast.episode_number : Constants.defaultPodcastEpisodeNumber,
            order: podcast ? podcast.order : Constants.defaultPodcastOrder,
            url: podcast ? podcast.url : null,
            android_url: podcast ? podcast.android_url : null,
            podcast_style_id: podcast ? podcast.podcast_style_id : null,
        }
    }

    handleSave = () => {
        const isValid = this.validate();
        if (!isValid) {
            return;
        }

        this.loadingSetter(true, async () => {
            try {
                const podcastData = this.getPodcastDataForSubmission();
                const response = await PortalApi.updatePodcast(podcastData);

                toastr.success(response.message);
                this.loadingSetter(false);
                this.props.afterSubmitListener();
            } catch (err) {
                this.loadingSetter(false);
                ErrorUtility.handleError(err, 'An error occurred while save podcast');
            }
        });
    }

    onIncreaseOrder = (fieldName) => {
        let state = this.state;
        state.podcast[fieldName] = parseInt(this.state.podcast[fieldName]) + 1;

        this.setState({state});
    }

    onDecreaseOrder = (fieldName) => {
        let state = this.state;
        state.podcast[fieldName] = parseInt(this.state.podcast[fieldName]) - 1;

        this.setState({state});
    }

    getPodcastStyleOptions = () => {
        if (!this.props.hasOwnProperty("podcastStyleList") || !this.props.podcastStyleList) {
            return [];
        }
        return this.props.podcastStyleList.map((style, index) => {
            return {
                key: `podcast-style-select-option-${index}`,
                label: style.name,
                value: style.id
            }
        })
    }

    getOptionIndexByValue = (optionsArray, value) => {
        return optionsArray.findIndex(option => option.value == value);
    }

    render = () => {
        const {
            podcast_name,
            episode_name,
            episode_number,
            order,
            url,
            android_url,
            podcast_style_id
        } = this.state.podcast;
        const isPodcastEdit = !!this.props.podcastForEdit;
        const podcastStyleOptions = this.getPodcastStyleOptions();
        const podcastStyleSelectedIndex = this.getOptionIndexByValue(podcastStyleOptions, podcast_style_id);

        return (
            <CommonModal
                modalTitle={(isPodcastEdit ? 'Edit' : 'Add New') + ' Podcast'}
                onClose={this.props.toggleModal}
            >
                {this.state.loading &&
                    <LoadingIndicator />
                }
                <div className="modal-row">
                    <div>
                        <label>Podcast Name</label>
                        <input
                            type="text"
                            name="podcast_name"
                            placeholder="Podcast Name"
                            value={podcast_name}
                            onChange={this.handleChange}
                            onBlur={(e) => {
                                this.handleChange({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.value ? e.target.value.trim() : null
                                    }
                                });
                            }}
                            maxLength={128}
                        />
                    </div>
                </div>
                <div className="modal-row">
                    <div className="w-full">
                        <label>Episode Name</label>
                        <input
                            type="text"
                            name="episode_name"
                            placeholder="Episode Name"
                            value={episode_name}
                            onChange={this.handleChange}
                            onBlur={(e) => {
                                this.handleChange({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.value ? e.target.value.trim() : null
                                    }
                                });
                            }}
                            maxLength={128}
                        />
                    </div>
                    <div className="w-150">
                        <label>Episode Number</label>
                        <div className="number">
                            <button className="number-minus" type="button"
                                    onClick={() => {this.onDecreaseOrder("episode_number")}}
                            >
                                -
                            </button>
                            <input
                                type="number"
                                name="episode_number"
                                placeholder="Episode Number"
                                value={episode_number}
                                onChange={this.handleChange}
                                onBlur={(e) => {
                                    this.handleChange({
                                        target: {
                                            name: e.target.name,
                                            value: e.target.value ? e.target.value.trim() : null
                                        }
                                    });
                                }}
                                maxLength={4}
                            />
                            <button className="number-plus" type="button"
                                    onClick={() => {this.onIncreaseOrder("episode_number")}}
                            >
                                +
                            </button>
                        </div>
                    </div>
                </div>
                <div className="modal-row">
                    <div className="w-full">
                        <label>Url for IOS</label>
                        <input
                            type="text"
                            name="url"
                            placeholder="URL FOR IOS"
                            value={url}
                            onChange={this.handleChange}
                            onBlur={(e) => {
                                this.handleChange({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.value ? e.target.value.trim() : null
                                    }
                                });
                            }}
                            maxLength={1024}
                        />
                    </div>
                    <div className="w-150">
                        <label>Order</label>
                        <div className="number">
                            <button className="number-minus" type="button"
                                    onClick={() => {this.onDecreaseOrder("order")}}
                            >
                                -
                            </button>
                            <input
                                type="number"
                                name="order"
                                placeholder="Order"
                                value={order}
                                onChange={this.handleChange}
                                onBlur={(e) => {
                                    this.handleChange({
                                        target: {
                                            name: e.target.name,
                                            value: e.target.value ? e.target.value.trim() : null
                                        }
                                    });
                                }}
                                maxLength={4}
                            />
                            <button className="number-plus" type="button"
                                    onClick={() => {this.onIncreaseOrder("order")}}
                            >
                                +
                            </button>
                        </div>
                    </div>
                </div>
                <div className="modal-row">
                    <div className="w-full">
                        <label>Url for Android</label>
                        <input
                            type="text"
                            name="android_url"
                            placeholder="URL FOR ANDROID"
                            value={android_url}
                            onChange={this.handleChange}
                            onBlur={(e) => {
                                this.handleChange({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.value ? e.target.value.trim() : null
                                    }
                                });
                            }}
                            maxLength={1024}
                        />
                    </div>
                </div>
                <div className="modal-row">
                    <div>
                        <label>Podcast Style</label>
                        <Select
                            label={"Podcast Style"}
                            className="select"
                            value={podcastStyleSelectedIndex !== -1 ? podcastStyleOptions[podcastStyleSelectedIndex] : ""}
                            options={podcastStyleOptions}
                            onChange={(podcastStyle) => {this.handleChange({
                                target: {
                                    name: "podcast_style_id",
                                    value: podcastStyle.value
                                }
                            });}}
                        />
                    </div>
                </div>
                <div className="controls">
                    <button className="cancel" onClick={this.props.toggleModal}>Cancel</button>
                    <button onClick={this.handleSave}>Save and Close</button>
                </div>
            </CommonModal>
        );
    }
};
