import React from "react";
import {Link} from "react-router-dom";
import {withRouter} from "react-router";
import {AuthUtility} from "../common/AuthUtility";
import {TextUtility} from "../common/TextUtility";
import {ErrorUtility} from "../common/ErrorUtility";
import {ValidationUtility} from "../common/ValidationUtility";
import {Constants} from "../common/Constants";
import {PortalApi} from "../common/PortalApi";
import {Header} from "../common/Header";

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            passwordConfirmation: ''
        };
    }

    componentDidMount = () => {
        if (AuthUtility.isLoggedIn()) {
            this.props.history.push(Constants.routes.settingsUsers);
        }
    }

    handleChange = (event) => {
        let newState = this.state;
        if (!newState.hasOwnProperty(event.target.name)) {
            return;
        }
        newState[event.target.name] = event.target.value;
        this.setState(newState);
    }

    validate = () => {
        const {
            first_name,
            last_name,
            email,
            password,
            passwordConfirmation
        } = this.state;

        let errorMessages = [];

        if (!ValidationUtility.validateEmail(email)) {
            errorMessages.push(`Invalid email: ${email}.`);
        }

        if (!ValidationUtility.validateText(first_name) || TextUtility.normalizeTextFromInput(first_name).length < 3) {
            errorMessages.push(`Invalid First Name`);
        }

        if (!ValidationUtility.validateText(last_name) || TextUtility.normalizeTextFromInput(last_name).length < 3) {
            errorMessages.push(`Invalid Last Name`);
        }

        if (!ValidationUtility.validatePassword(password) || password !== passwordConfirmation) {
            errorMessages.push(`Password does not meet all the requirements: ${password}`);
        } else if (password !== passwordConfirmation) {
            errorMessages.push(`Passwords do not match`);
        }

        ValidationUtility.showErrorMessages(errorMessages);

        return !errorMessages.length;
    }

    handleSubmit = () => {
        if (!this.validate()) {
            return;
        }

        const userData = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            password: this.state.password
        };

        PortalApi.register(userData).then((response) => {
            if (response.hasOwnProperty('message')) {
                toastr.success(response['message'].toString());
            } else {
                toastr.success("New user registered");
            }
            this.props.history.push(Constants.routes.login);
        })
        .catch(err => {
            ErrorUtility.handleError(err, 'An error occurred during registration');
        });
    }

    render = () => {
        const {
            first_name,
            last_name,
            email,
            password,
            passwordConfirmation
        } = this.state;

        return (
            <div className="page-wrapper center-aligned">
                <Header
                    history={this.props.history}
                />
                <div className="page-heading">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="page-title">Register</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content center-aligned">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="form">
                                    <div className="form-row">
                                        <div>
                                            <label>First Name</label>
                                            <input name="first_name" type="text" placeholder="First Name" value={first_name} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div>
                                            <label>Last Name</label>
                                            <input name="last_name" type="text" placeholder="Last Name" value={last_name} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div>
                                            <label>Email</label>
                                            <input name="email" type="email" placeholder="Email" value={email} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div>
                                            <label>Password</label>
                                            <input name="password" type="password" placeholder="Password" value={password} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div>
                                            <div>Your password should contain:</div>
                                            <ul>
                                                <li>At least one digit</li>
                                                <li>At least one lower case letter</li>
                                                <li>At least one upper case letter</li>
                                                <li>Be at least 8 characters long</li>
                                                <li>Special characters supported: ~@$!%*#?&</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div>
                                            <label>Password Confirm</label>
                                            <input name="passwordConfirmation" type="password" placeholder="Confirm Password" value={passwordConfirmation} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="form-row controls">
                                        <button type="submit" onClick={this.handleSubmit}>
                                            Submit
                                        </button>
                                    </div>
                                    <div className="form-row">
                                        <div className="center-aligned">
                                            <Link to={Constants.routes.login}>Login</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default withRouter(Register);
