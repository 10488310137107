import React from "react";
import {displayForPermissions} from "../../common/AuthorizedComponent";
import {Constants} from "../../common/Constants";
import {UrlUtility} from "../../common/UrlUtility";
import {ErrorUtility} from "../../common/ErrorUtility";
import {ReactTable} from "../../common/ReactTable";
import {PortalApi} from "../../common/PortalApi";
import {EditArticleModal} from "./EditArticleModal";
import {DeleteArticleModal} from "./DeleteArticleModal";
import {Header} from "../../common/Header";
import {FooterMenu} from "../../common/FooterMenu";

export const ArticlesPage = displayForPermissions(
    [Constants.permissions.settingsManage],
    class extends React.Component {
        constructor(props) {
            super(props);

            const urlParams = UrlUtility.getParamsFromUrl(props.location.search);
            const filter = UrlUtility.getParamValue(urlParams, "filter", []);
            const sort = UrlUtility.getParamValue(urlParams, "sort", []);
            const pageSize = UrlUtility.getParamValue(urlParams, "pageSize", Constants.defaultSettingsUsersPageSize);

            this.state = {
                loading: false,
                sort: sort,
                page: 0,
                filter: filter,
                pageSize: pageSize,
                articleList: [],
                showArticleAddPopup: false,
                showArticleDeletePopup: false,
            };
        }

        componentDidMount = () => {
            this.navigateToFilteredPage();
        }

        loadingSetter = (value = false, callback = () => {}) => {
            this.setState({
                loading: value
            }, callback);
        }

        refreshPageData = (state = null) => {
            this.setState(oldState => {
                return {
                    loading: true,
                    filter: state ? state.filter : oldState.filter,
                    sort: state ? state.sort : oldState.sort,
                    page: state ? state.page : oldState.page,
                    pageSize: state ? state.pageSize : oldState.pageSize
                }
            }, async () => {
                const params = {
                    filter: this.state.filter,
                    sort: this.state.sort,
                    page: this.state.page,
                    pageSize: this.state.pageSize,
                    showAll: true,
                };
                try {
                    const articlesResponse = await PortalApi.fetchArticles(params);

                    this.setState({
                        loading: false,
                        articleList: articlesResponse.articles,
                        filter: this.state.filter,
                        sort: this.state.sort,
                        page: this.state.page,
                        pageSize: this.state.pageSize,
                        pages: Math.ceil(parseInt(articlesResponse.count) / this.state.pageSize)
                    }, () => {
                        this.navigateToFilteredPage();
                    });

                } catch (err) {
                    ErrorUtility.handleError(err, 'Internal server error: Can&#039;t fetch articles list.');
                    this.setState({loading: false});
                };
            });
        }

        navigateToFilteredPage = () => {
            const {filter, sort, pageSize} = this.state;

            let query = {};
            if (filter != null) {
                query['filter'] = filter;
            }
            if (sort != null) {
                query['sort'] = sort;
            }
            if (pageSize != null) {
                query['pageSize'] = pageSize;
            }

            this.props.history.push('/settings/articles?' + $.param(query));
        }

        getArticleByIdFromList = (id) => {
            if (!this.state.articleList || !id) {
                return null;
            }
            return this.state.articleList.find((article) => {
                if (article.id == id) {
                    return article;
                }
            });
        }

        toggleArticleAddPopup = (articleId = null) => {
            this.setState ({
                articleForEditId: articleId,
                showArticleAddPopup: !this.state.showArticleAddPopup
            });
        }

        afterArticleSave = () => {
            this.setState({
                showArticleAddPopup: false,
                articleForEditId: null
            }, () => {this.refreshPageData();});
        }

        toggleArticleDeletePopup = (articleId = null) => {
            this.setState ({
                articleForEditId: articleId,
                showArticleDeletePopup: !this.state.showArticleDeletePopup
            });
        }

        onArticleDelete = (articleId) => {
            this.loadingSetter(true, () => {
                PortalApi.deleteArticle(articleId)
                    .then((response) => {
                        toastr.success(response.message);
                        this.loadingSetter(false);
                        this.toggleArticleDeletePopup();
                        this.refreshPageData();
                    })
                    .catch((error) => {
                        this.loadingSetter(false);
                        ErrorUtility.handleError(error, 'An error occurred while delete an article.');
                    });
            });
        }

        getTableColumns = () => {
            const columns = [{
                Header: () => (
                    <span>
                        Title
                            <img
                                src="/static/image/icon-filter-down.svg"
                            />
                    </span>
                ),
                accessor: 'title'
            }, {
                Header: () => (
                    <span>
                        Source
                        <img
                            src="/static/image/icon-filter-down.svg"
                        />
                    </span>
                ),
                accessor: 'source'
            }, {
                Header: () => (
                    <span>
                        Order
                        <img
                            src="/static/image/icon-filter-down.svg"
                        />
                    </span>
                ),
                accessor: 'order'
            }, {
                accessor: 'id',
                Cell: ({row}) => (
                    <button onClick={() => {this.toggleArticleDeletePopup(row.original.id);}}>
                        <img className="img-responsive" src='/static/image/icon-delete.svg' alt='icon-delete' />
                    </button>
                ),
                disableFilters: true,
            }];

            return columns;
        }

        render = () => {
            return (
                <div className="page-wrapper articles-page">
                    <Header
                        history={this.props.history}
                    />
                    <div className="page-heading">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="page-title">Curated Articles</h1>
                                    <p>Articles display in descending order in the app.
                                        <br/>
                                        The top 4 items in the order are displayed on the homescreen.
                                    </p>
                                    <div className="page-heading-controls">
                                        <button className="compact" onClick={() => {this.toggleArticleAddPopup();}}>Add New Article</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ReactTable
                                        data={this.state.articleList}
                                        columns={this.getTableColumns()}
                                        className={'articles-list-content'}
                                        pages={this.state.pages}
                                        loading={this.state.loading}
                                        defaultSorted={this.state.sort}
                                        defaultFiltered={this.state.filter}
                                        defaultPageSize={this.state.pageSize}
                                        onFetchData={(state) => this.refreshPageData(state)}
                                        getTdProps={({row, column}) => {
                                            return {
                                                onClick: () => {
                                                    if(column.id !== "id") {
                                                        this.toggleArticleAddPopup(row.original.id);
                                                    }
                                                }
                                            };
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {this.state.showArticleAddPopup &&
                            <EditArticleModal
                                toggleModal={this.toggleArticleAddPopup}
                                afterSubmitListener={this.afterArticleSave}
                                articleForEdit={this.getArticleByIdFromList(this.state.articleForEditId)}
                            />
                        }
                        {this.state.showArticleDeletePopup &&
                            <DeleteArticleModal
                                loading={this.state.loading}
                                toggleModal={this.toggleArticleDeletePopup}
                                afterSubmitListener={this.onArticleDelete}
                                articleForEdit={this.state.articleForEditId}
                            />
                        }
                    </div>
                    <FooterMenu />
                </div>
            )
        }
    }
);