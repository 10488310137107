import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import {AuthUtility} from "./AuthUtility";

const PrivateRoute = ({ component: Component, ...rest }) => {
  	const isLoggedIn = AuthUtility.isLoggedIn();

  	return (
    	<Route
      		{...rest}
      		render={props =>
        		isLoggedIn ? (
          			<Component {...props} />
        		) : (
          			<Redirect to={{ pathname: '/login'}} />
        		)
      		}
    	/>
  	)
};

export default PrivateRoute;
