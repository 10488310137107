import {ErrorUtility} from "./ErrorUtility";
import {ApiUtility} from "./ApiUtility";

export const PortalApi = {
    register: function (data) {
        if (!data) {
            return;
        }

        let url = `/api/register`;
        return ApiUtility.apiFetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
    },

    login: function (data) {
        if (!data) {
            return;
        }

        let url = `/api/login`;
        return ApiUtility.apiFetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
    },

    logout: function (data) {
        if (!data) {
            return;
        }

        let url = `/api/logout`;
        return ApiUtility.apiFetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
    },

    sendRestorePasswordCode: function (data) {
        if (!data) {
            return;
        }

        let url = `/api/send-restore-password-code`;
        return ApiUtility.apiFetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
    },

    resetPassword: function (data) {
        if (!data) {
            return;
        }

        let url = `/api/reset-password`;
        return ApiUtility.apiFetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
    },

    refreshToken: function (token) {
        if (!token) {
            return;
        }

        let url = `/api/token-refresh`;
        return ApiUtility.apiFetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token: token}),
        });
    },

    // fileObject = {name: string, mime_type: string, size: int, base64: base64 string for contents}
    uploadFile: function (fileObject) {
        return new Promise((resolve, reject) => {
            ApiUtility.apiFetch('/api/file-items/upload', {
                method: 'POST',
                body: JSON.stringify({file: fileObject}),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    ErrorUtility.handleError(err, 'An error occurred while uploading file.');
                    reject(err);
                });
        });
    },

    fetchRoles: function (params = {}) {
        return new Promise((resolve, reject) => {
            ApiUtility.apiFetch(`/api/roles?` + $.param(params))
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                    ErrorUtility.handleError(err, `An error occurred while retrieving roles.`);
                });
        });
    },

    fetchUsers: function (params = {}) {
        return new Promise((resolve, reject) => {
            ApiUtility.apiFetch(`/api/users?` + $.param(params))
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                    ErrorUtility.handleError(err, `An error occurred while retrieving users.`);
                });
        });
    },

    updateUser: function (data = {}) {
        return new Promise((resolve, reject) => {
            const url = `/api/users/update`;
            ApiUtility.apiFetch(
                `${url}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }
            ).then((response) => {
                resolve(response);
            })
                .catch((err) => {
                    reject(err);
                    ErrorUtility.handleError(err, `An error occurred while update user.`);
                });
        });
    },

    fetchQuizQuestions: function (params = {}) {
        return new Promise((resolve, reject) => {
            ApiUtility.apiFetch(`/api/quiz-questions?` + $.param(params))
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                    ErrorUtility.handleError(err, `An error occurred while retrieving quiz questions.`);
                });
        });
    },

    updateQuizQuestion: function (data = {}) {
        return new Promise((resolve, reject) => {
            const url = `/api/quiz-questions/update`;
            ApiUtility.apiFetch(
                `${url}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }
            ).then((response) => {
                resolve(response);
            })
                .catch((err) => {
                    reject(err);
                    ErrorUtility.handleError(err, `An error occurred while update quiz question.`);
                });
        });
    },

    fetchArticles: function (params = {}) {
        return new Promise((resolve, reject) => {
            ApiUtility.apiFetch(`/api/articles?` + $.param(params))
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                    ErrorUtility.handleError(err, `An error occurred while retrieving articles.`);
                });
        });
    },

    updateArticle: function (data = {}) {
        return new Promise((resolve, reject) => {
            const url = `/api/articles/update`;
            ApiUtility.apiFetch(
                `${url}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }
            ).then((response) => {
                resolve(response);
            })
                .catch((err) => {
                    reject(err);
                    ErrorUtility.handleError(err, `An error occurred while update an article.`);
                });
        });
    },

    deleteArticle: function (articleId) {
        return new Promise((resolve, reject) => {
            const url = `/api/articles/${articleId}`;
            ApiUtility.apiFetch(
                `${url}`,
                {
                    method: 'DELETE'
                }
            ).then((response) => {
                resolve(response);
            })
                .catch((err) => {
                    reject(err);
                    ErrorUtility.handleError(err, `An error occurred while delete an article.`);
                });
        });
    },

    fetchPodcastStyles: function () {
        return new Promise((resolve, reject) => {
            ApiUtility.apiFetch(`/api/podcast-styles`)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                    ErrorUtility.handleError(err, `An error occurred while retrieving podcast styles.`);
                });
        });
    },

    fetchPodcasts: function (params = {}) {
        return new Promise((resolve, reject) => {
            ApiUtility.apiFetch(`/api/podcasts?` + $.param(params))
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                    ErrorUtility.handleError(err, `An error occurred while retrieving podcasts.`);
                });
        });
    },

    updatePodcast: function (data = {}) {
        return new Promise((resolve, reject) => {
            const url = `/api/podcasts/update`;
            ApiUtility.apiFetch(
                `${url}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }
            ).then((response) => {
                resolve(response);
            })
                .catch((err) => {
                    reject(err);
                    ErrorUtility.handleError(err, `An error occurred while update a podcast.`);
                });
        });
    },

    deletePodcast: function (podcastId) {
        return new Promise((resolve, reject) => {
            const url = `/api/podcasts/${podcastId}`;
            ApiUtility.apiFetch(
                `${url}`,
                {
                    method: 'DELETE'
                }
            ).then((response) => {
                resolve(response);
            })
                .catch((err) => {
                    reject(err);
                    ErrorUtility.handleError(err, `An error occurred while delete a podcast.`);
                });
        });
    },

    deleteUser: function (userId) {
        return new Promise((resolve, reject) => {
            const url = `/api/users/${userId}`;
            ApiUtility.apiFetch(
                `${url}`,
                {
                    method: 'DELETE'
                }
            ).then((response) => {
                resolve(response);
            })
                .catch((err) => {
                    reject(err);
                    ErrorUtility.handleError(err, `An error occurred while delete user.`);
                });
        });
    },

    deleteUserLogs: function (userId) {
        return new Promise((resolve, reject) => {
            const url = `/api/users/${userId}/logs`;
            ApiUtility.apiFetch(
                `${url}`,
                {
                    method: 'DELETE'
                }
            ).then((response) => {
                resolve(response);
            })
                .catch((err) => {
                    reject(err);
                    ErrorUtility.handleError(err, `An error occurred while delete user quiz logs.`);
                });
        });
    },
};
