import React from "react";
import {CommonModal} from "./CommonModal";

export const ConfirmationModal = class extends React.Component {
    closeModal = () => {
        if (typeof this.props.toggleModal == "function") {
            this.props.toggleModal();
        }
    }

    confirmAction = () => {
        if (typeof this.props.onSubmit == "function") {
            this.props.onSubmit();
        }
    }

    render () {
        const {
            modalTitle = "Confirm Action",
            description = "",
            closeButtonText = "Close",
            confirmButtonText = "Confirm"
        } = this.props;

        return (
            <CommonModal
                modalTitle={modalTitle}
                onClose={this.closeModal}
            >
                <div className="modal-row">
                    <div>
                        <p>{description}</p>
                    </div>
                </div>
                <div className="controls">
                    <button className="cancel" onClick={this.closeModal}>{closeButtonText}</button>
                    <button onClick={this.confirmAction}>{confirmButtonText}</button>
                </div>
            </CommonModal>
        );
    }
};
