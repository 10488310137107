import React from 'react'
import {Constants} from "./Constants";

const defaultButton = props => (
    <button type="button" {...props}>
        {props.children}
    </button>
);

const defaultPreviousText = () => (
    <React.Fragment>
        <img
            src="/static/image/pagination-icon-prev.svg"
        />
        <span>Prev</span>
    </React.Fragment>
);

const defaultNextText = () => (
    <React.Fragment>
        <span>Next</span>
        <img
            src="/static/image/pagination-icon-next.svg"
        />
    </React.Fragment>
);

const PaginationComponent = (props) => {
    const {
        style,
        // Computed
        pages,
        // Props
        pageIndex = 0,
        showPageSizeOptions = true,
        pageSizeOptions = Constants.pageSizeOptions,
        pageSize,
        showPageJump = true,
        PreviousComponent = defaultButton,
        NextComponent = defaultButton,
        canPreviousPage,
        canNextPage,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        PreviousTextComponent = defaultPreviousText,
        NextTextComponent = defaultNextText,
        ofText = "of",
        rowsText = "rows"
    } = props;

    if (!pages || pages == 1) {
        return null;
    }

    const onChangeHandler = (e) => {
        const newPage = e.target.value ? Number(e.target.value) - 1 : 0;
        gotoPage(newPage);
    };

    return (
        <div className={'-pagination'} style={style}>
            <div className="-previous">
                <PreviousComponent
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                >
                    <PreviousTextComponent />
                </PreviousComponent>
            </div>
            <div className="-center">
                {showPageSizeOptions && (
                    <span className="select-wrap -pageSizeOptions">
                        <select onChange={e => setPageSize(Number(e.target.value))} value={Number(pageSize)}>
                            {pageSizeOptions.map((option, i) => (
                                <option key={i} value={option}>
                                    {option} {rowsText}
                                </option>
                            ))}
                        </select>
                    </span>
                )}
                <span className="-pageInfo">
                    {showPageJump ?
                        <div className="-pageJump">
                            <input
                                type="number"
                                value={pageIndex + 1}
                                onChange={onChangeHandler}
                                onBlur={onChangeHandler}
                                onKeyPress={onChangeHandler}
                            />
                        </div>
                        : <span className="-currentPage">{pageIndex + 1}</span>
                    }
                    <div className="-totalPages-wrapper">
                        <span>{ofText}</span>
                        <span className="-totalPages">{pages || 1}</span>
                    </div>
                </span>
            </div>
            <div className="-next">
                <NextComponent
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                >
                    <NextTextComponent />
                </NextComponent>
            </div>
        </div>
    )
}
export default PaginationComponent;