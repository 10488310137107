import React from "react";
import {CommonModal} from "../../common/CommonModal";
import {Constants} from "../../common/Constants";
import {LoadingIndicator} from "../../common/LoadingIndicator";

export const DeleteUserModal = class extends React.Component {
    closeModal = () => {
        if (typeof this.props.toggleModal == "function") {
            this.props.toggleModal();
        }
    }

    confirmAction = () => {
        if (typeof this.props.onSubmit == "function") {
            this.props.onSubmit();
        }
    }

    render = () => {
        const {
            modalTitle = "Confirm Action",
            description = "",
            closeButtonText = "Cancel",
            confirmButtonText = "Confirm",
            isChildModal = false,
        } = this.props;

        return (
            <CommonModal
                modalTitle={"Are you sure?"}
                onClose={this.closeModal}
                modalType={Constants.modal.type.delete}
                isChildModal={isChildModal}
            >
                {this.props.loading &&
                    <LoadingIndicator />
                }
                <div className="modal-row">
                    <div>
                        <p>{description}</p>
                    </div>
                </div>
                <div className="controls">
                    <button className="cancel cancel-delete" onClick={this.closeModal}>{closeButtonText}</button>
                    <button className="delete" onClick={this.confirmAction}>{confirmButtonText}</button>
                </div>
            </CommonModal>
        );
    }
};
